import React from "react";
import { useNavigate } from "react-router-dom";
import "./Footer.css";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer className="py-6 text-white" style={{ zIndex: "1" }}>
      <div className="container mx-auto px-4">
        <div
          className="grid grid-cols-1 md:grid-cols-3 gap-8 footerContent"
          style={{ display: "flex", justifyContent: "space-around" }}
        >
          <div className="contact-links">
            <h5 className="text-lg font-semibold mb-2">Contact Us</h5>
            <ul>
              <a href="mailto:kalapayshop@gmail.com">
                Email: kalapayshop@gmail.com
              </a>
              <li>Phone: 0962-285-6811</li>
              <li>Location: Basco, Batanes</li>
            </ul>
          </div>
          <div>
            <h5 className="text-lg font-semibold mb-2">Quick Links</h5>
            <ul>
              <li>
                <span
                  className="text-white hover:text-gray-300 cursor-pointer"
                  onClick={() => navigate("/about")}
                >
                  About Us
                </span>
              </li>
              <li>
                <span
                  className="text-white hover:text-gray-300 cursor-pointer"
                  onClick={() => navigate("/categories")}
                >
                  Categories
                </span>
              </li>
              <li>
                <span
                  className="text-white hover:text-gray-300 cursor-pointer"
                  onClick={() => navigate("/contact")}
                >
                  Contact
                </span>
              </li>
            </ul>
          </div>
          <div>
            <h5 className="text-lg font-semibold mb-2">Follow Us</h5>
            <ul className="flex  flex-col ">
              <li>
                <a
                  href="https://www.facebook.com/profile.php?id=61560549183562"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/kalapayshop?igsh=ampibTY5b3lkN2c4&fbclid=IwZXh0bgNhZW0CMTAAAR3iI86pe0LJORXORPlYwsQACW-OuF9WF5zadSyBGcoetbMT0dv8YclF_uY_aem_AQiSROUHKAOlC3Izm7NgZji6fb1emPamzI1U2Gm-QkkfaV9STUYDK2jiNRgYlbzhki7vqRHvGBB9cQm_PWZm-oRK"
                  target="_blank"
                  className="text-white hover:text-gray-300"
                >
                  Instagram
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="mt-8 border-t border-gray-600 pt-6 text-center">
          <p className="text-sm">
            Copyright &copy; Kalapay 2024. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
