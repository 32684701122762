import React, { useContext, useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { CartContext } from "../contexts/CartContext";
import { ProductContext } from "../contexts/ProductContext";
import { SidebarContext } from "../contexts/SidebarContext";
import axios from "axios";
import { formatPrice } from "../components/formatPrice";
import { Button, notification, Select } from "antd";
import { IoMdRemove, IoMdAdd } from "react-icons/io";
import "./ProductDetails.css";

const ProductDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { addToCart } = useContext(CartContext);
  const { updateProductStock } = useContext(ProductContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const [product, setProduct] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [currentImage, setCurrentImage] = useState("");
  const [currentIndex, setCurrentIndex] = useState(0);
  const [quantity, setQuantity] = useState(1);
  const [selectedSize, setSelectedSize] = useState(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsLoading(true);

    axios
      .get(
        `http://kalapay.shop/KalapayAPIupload/getProductDetails.php?id=${id}`
      )
      .then((response) => {
        console.log("API Response:", response.data); // Log the response data
        if (response.data.error) {
          console.error("API Error:", response.data.error);
          setProduct(null);
        } else {
          setProduct(response.data);
          setCurrentImage(response.data.image); // Set the main image
        }
        setIsLoading(false);
      })
      .catch((error) => {
        console.error("Network or server error:", error);
        setIsLoading(false);
      });
  }, [id]);

  const handleQuantityChange = (value) => {
    if (value >= 1 && value <= product.stock) {
      setQuantity(value);
    }
  };

  const decreaseAmount = () => {
    if (quantity > 1) {
      setQuantity(quantity - 1);
      setProduct((prevProduct) => ({
        ...prevProduct,
        stock: prevProduct.stock + 1,
      }));
      updateProductStock(product.id, 1); // Increase stock by 1
    }
  };

  const increaseAmount = () => {
    if (quantity < product.stock) {
      setQuantity(quantity + 1);
      setProduct((prevProduct) => ({
        ...prevProduct,
        stock: prevProduct.stock - 1,
      }));
      updateProductStock(product.id, -1); // Decrease stock by 1
    }
  };

  const handleAddToCart = () => {
    if (product.sizes && product.sizes.length > 0 && !selectedSize) {
      notification.warning({
        message: "Size Required",
        description: "Please select a size before adding to cart.",
      });
      return;
    }

    if (product.stock >= quantity) {
      addToCart({ ...product, quantity, selectedSize }, product.id, quantity);
      updateProductStock(product.id, -quantity); // Subtract the quantity from the stock
      setProduct((prevProduct) => ({
        ...prevProduct,
        stock: prevProduct.stock - quantity,
      }));
      toggleSidebar();
    } else {
      notification.warning({
        message: "Out of Stock",
        description:
          "The product is out of stock. You can add it to your wishlist for notification when it becomes available.",
      });
    }
  };

  const handleAddToWishlist = () => {
    notification.info({
      message: "Added to Wishlist",
      description: "You will be notified when the product is available.",
    });
  };

  const handleThumbnailClick = (image, index) => {
    setCurrentImage(image);
    setCurrentIndex(index);
  };

  const handlePrevImage = () => {
    const images = [
      product.image,
      product.product_img_one,
      product.product_img_two,
      product.product_img_three,
    ].filter(Boolean); // Filter out undefined images
    const newIndex = currentIndex > 0 ? currentIndex - 1 : 0;
    setCurrentImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  const handleNextImage = () => {
    const images = [
      product.image,
      product.product_img_one,
      product.product_img_two,
      product.product_img_three,
    ].filter(Boolean);
    const newIndex =
      currentIndex < images.length - 1 ? currentIndex + 1 : currentIndex;
    setCurrentImage(images[newIndex]);
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const imageElement = document.querySelector(".fade-in");
    if (imageElement) {
      imageElement.classList.remove("loaded");
      setTimeout(() => {
        imageElement.classList.add("loaded");
      }, 10);
    }
  }, [currentImage]);

  if (isLoading) {
    return (
      <section className="h-screen flex justify-center items-center">
        Loading...
      </section>
    );
  }

  if (!product) {
    return (
      <section className="h-screen flex justify-center items-center">
        Product not found
      </section>
    );
  }

  const {
    title,
    price,
    description,
    product_img_one,
    product_img_two,
    product_img_three,
    image,
    stock,
    category,
    sizes,
  } = product;

  const images = [
    image,
    product_img_one,
    product_img_two,
    product_img_three,
  ].filter(Boolean);

  return (
    <section className="product-details pt-32 lg:py-20 h-auto">
      <div className="container mx-auto px-11 product-details-main">
        <div
          className="flex flex-col lg:flex-row items-center content-wrap"
          style={{ alignItems: "flex-start" }}
        >
          <div className="flex justify-center items-center mb-8 lg:mb-0 product-images-container">
            <div className="relative w-full lg:w-auto">
              <img
                className="currentImg lg:w-auto max-w-full fixed-size-image fade-in"
                src={currentImage}
                alt={title}
                onLoad={() => {
                  const imageElement = document.querySelector(".fade-in");
                  if (imageElement) {
                    imageElement.classList.add("loaded");
                  }
                }}
              />
              {images.length > 1 && (
                <div className="absolute inset-0 flex items-center justify-between p-4">
                  {currentIndex > 0 && (
                    <Button
                      onClick={handlePrevImage}
                      shape="circle"
                      icon={
                        <svg
                          className="h-6 w-6 text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="false"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M15 19l-7-7 7-7"
                          />
                        </svg>
                      }
                    />
                  )}
                  {currentIndex < images.length - 1 && (
                    <Button
                      onClick={handleNextImage}
                      shape="circle"
                      icon={
                        <svg
                          className="h-6 w-6 text-gray-600"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                          aria-hidden="false"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5l7 7-7 7"
                          />
                        </svg>
                      }
                      style={{ marginLeft: "auto" }}
                    />
                  )}
                </div>
              )}
            </div>

            {images.length > 1 && (
              <div className="thumbnails-container">
                {images.map((img, index) => (
                  <img
                    key={index}
                    src={img}
                    alt={`product_img_${index}`}
                    className="thumbnail"
                    onClick={() => handleThumbnailClick(img, index)}
                    style={{
                      cursor: "pointer",
                      width: "70px",
                      height: "70px",
                      objectFit: "cover",
                    }}
                  />
                ))}
              </div>
            )}
          </div>
          <div className="text-left pdContent">
            {/* Product details and action buttons */}
            <h1
              className="text-[26px] font-medium mb-2 max-w-[450px] mx-auto lg:mx-0"
              style={{
                fontFamily: "Raleway",
                fontWeight: "bold",
                marginLeft: "0",
              }}
            >
              {title}
            </h1>
            <div
              className="text-xl  font-medium mb-2"
              style={{ fontFamily: "jost", color: "#74512d" }}
            >
              {formatPrice(price)}
            </div>
            <h1 style={{ fontFamily: "Raleway", fontSize: "1.3rem" }}>
              Product Description:
            </h1>
            <div>
              <div dangerouslySetInnerHTML={{ __html: description }} />
            </div>
            <div
              className="flex flex-1 max-w-[150px] items-center h-full text-primary font-medium mb-4 border border-black "
              style={{ height: "3rem", marginTop: "10px" }}
            >
              <div
                onClick={decreaseAmount}
                className="flex-1 flex justify-center items-center cursor-pointer h-full hover:bg-yellow-200 transition duration-200 ease-in-out"
              >
                <IoMdRemove />
              </div>
              <div className="h-full flex justify-center items-center px-2">
                {quantity}
              </div>
              <div
                onClick={increaseAmount}
                className="flex-1 h-full flex justify-center items-center cursor-pointer hover:bg-yellow-200 transition duration-200 ease-in-out"
              >
                <IoMdAdd />
              </div>
            </div>

            <div
              className="mb-4 text-left"
              style={{ fontFamily: "objectivity" }}
            >
              Stock remaining: {stock}
            </div>

            {sizes && sizes.length > 0 && (
              <div className="mb-4 selectSize">
                <h2 className="text-lg font-medium mb-2">Available Size:</h2>
                <Select
                  style={{ width: 120 }}
                  placeholder="Select size"
                  onChange={(value) => setSelectedSize(value)}
                >
                  {sizes.map((size, index) => (
                    <Select.Option key={index} value={size}>
                      {size}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            )}

            <div
              className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-4"
              style={{ marginTop: "30px", flexDirection: "column" }}
            >
              <Button
                className="button-add w-full md:w-auto"
                onClick={handleAddToCart}
                disabled={stock === 0}
                style={{ width: "100%", fontWeight: "500" }}
              >
                Add to Kalapay
              </Button>

              <Button
                className="button-back w-full md:w-auto"
                onClick={() => navigate(-1)}
                style={{
                  backgroundColor: "#543310",
                  color: "white",
                  fontSize: "1.1rem",
                  marginTop: "10px",
                  width: "100%",
                }}
              >
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProductDetails;
