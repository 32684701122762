import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { IoMdRemove, IoMdAdd } from "react-icons/io";
import { FiTrash2 } from "react-icons/fi";
import { Button } from "antd";
import { CartContext } from "../contexts/CartContext";
import { formatPrice } from "../components/formatPrice";

const CartItem = ({ item }) => {
  const { removeFromCart, increaseAmount, decreaseAmount } =
    useContext(CartContext);
  const { id, title, image, price, quantity, stock } = item;

  const handleRemove = () => {
    removeFromCart(id);
  };

  return (
    <div className="flex gap-x-4 py-2 lg:px-2 border-b border-gray-200 w-full font-light text-gray-500">
      <div className="w-full min-h-[150px] flex items-center gap-x-4">
        <Link to={`/product/${id}`}>
          <img className="max-w-[80px]" src={image} alt={title} />
        </Link>

        <div className="w-full flex flex-col">
          <div className="flex justify-between mb-2">
            <Link
              to={`/product/${id}`}
              className="text-sm uppercase font-medium max-w-[240px] text-primary hover:underline"
            >
              {title}
            </Link>
            <Button
              type="text"
              icon={<FiTrash2 size={24} />}
              onClick={handleRemove}
              danger
            />
          </div>
          <div className="flex gap-x-2 h-[36px] text-sm">
            <div
              className="flex flex-1 max-w-[100px] items-center h-full text-primary font-medium"
              style={{ border: "1px solid gray" }}
            >
              <div
                onClick={() => decreaseAmount(id)}
                className="flex-1 flex justify-center items-center cursor-pointer h-full"
              >
                <IoMdRemove />
              </div>

              <div className="h-full flex justify-center items-center px-2">
                {quantity}
              </div>

              <div
                onClick={() => (quantity < stock ? increaseAmount(id) : null)}
                className={`flex-1 h-full flex justify-center items-center cursor-pointer ${
                  quantity >= stock ? "opacity-50 cursor-not-allowed" : ""
                }`}
              >
                <IoMdAdd />
              </div>
            </div>
            <div className="flex-1 flex items-center justify-around">
              {formatPrice(price)} {/* Format the individual item price */}
            </div>
            <div className="flex-1 flex justify-end items-center text-primary font-medium">
              {formatPrice(price * quantity)}{" "}
              {/* Format the total item price */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CartItem;
