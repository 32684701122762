import React, { useContext, useEffect, useState } from "react";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";
import { UserContext } from "../contexts/UserContext";
import { WishlistContext } from "../contexts/WishlistContext";
import { BsBag } from "react-icons/bs";
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiOutlineUser,
  AiFillHeart,
  AiOutlineDown,
} from "react-icons/ai";
import { Dropdown, Menu } from "antd";

import { Link, NavLink, useNavigate } from "react-router-dom";
import { motion, AnimatePresence } from "framer-motion";
import "../components/Header.css";
import Logo from "../img/kalapayFinalLogoCompressed.webp";
import SearchIcon from "./SearchIcon";
import { useMediaQuery } from "react-responsive";

const Header = () => {
  const [isActive, setIsActive] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { isOpen, toggleSidebar } = useContext(SidebarContext);
  const { itemAmount, setCart } = useContext(CartContext);
  const { user, setUser } = useContext(UserContext);
  const { wishlist } = useContext(WishlistContext);
  const navigate = useNavigate();
  const { clearCart } = useContext(CartContext);
  const { clearWishlist } = useContext(WishlistContext);
  const isMobile = useMediaQuery({ maxWidth: 767 });
  useEffect(() => {
    const handleScroll = () => {
      setIsActive(window.scrollY > 60);
    };

    const handleResize = () => {
      if (window.innerWidth >= 60) {
        window.addEventListener("scroll", handleScroll);
      } else {
        window.removeEventListener("scroll", handleScroll);
      }
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!user) {
      const storedUser = localStorage.getItem("user");
      if (storedUser) {
        const parsedUser = JSON.parse(storedUser);
        setUser(parsedUser);
        console.log("Loaded user from localStorage:", parsedUser);
      }
    } else {
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        setCart(JSON.parse(storedCart));
      }
    }
  }, [user, setUser, setCart]);

  useEffect(() => {
    if (user) {
      console.log("Header user:", user);
      console.log("User ID:", user.id);
      console.log("User Email:", user.email);
      console.log("User Username:", user.username);
    }
  }, [user]);

  const handleMenuToggle = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleLogout = () => {
    localStorage.removeItem("user");
    clearWishlist();
    setUser(null);
    navigate("/login");
    setIsMenuOpen(false); // Close the menu on logout
  };

  const handleOrdersClick = () => {
    if (user) {
      navigate("/orders");
    } else {
      navigate("/login");
    }
    setIsMenuOpen(false); // Close the menu on orders click
  };

  const textColorClass = isActive ? "header-black-text" : "header-white-text";

  const linkVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  const dropdownMenu = (
    <Menu>
      <Menu.Item key="orders" onClick={handleOrdersClick}>
        Orders
      </Menu.Item>
      <Menu.Item
        key="account"
        onClick={() => {
          navigate("/account-settings");
          setIsMenuOpen(false);
        }}
      >
        Change Password
      </Menu.Item>
      <Menu.Item key="logout" onClick={handleLogout}>
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <header
      className={`${
        isActive ? "py-4 shadow-md header-blur" : "py-4 header-initial"
      } fixed w-full z-10 transition-all`}
    >
      <div className="container px-4 sm:px-6 lg:px-8 mx-auto flex items-center justify-between h-full">
        <div className="lg:hidden flex items-center">
          <button
            onClick={handleMenuToggle}
            className={`text-2xl mr-4 menu-icon ${
              isMenuOpen ? "open" : ""
            } ${textColorClass}`}
            style={{ padding: "10px" }}
          >
            {isMenuOpen ? <AiOutlineClose /> : <AiOutlineMenu />}
          </button>
        </div>
        <Link
          to={"/"}
          className={`flex-grow lg:flex-grow-0 flex justify-center lg:justify-start ${textColorClass}`}
        >
          <div
            className="flex items-center"
            style={{ display: "flex", alignItems: "center" }}
          >
            <img
              src={Logo}
              alt=""
              style={{ width: "35px", marginRight: "5px" }}
            />
            <span
              className="text-xl font-bold"
              style={{
                fontFamily: "dosis",
                letterSpacing: "2px",
                fontSize: "1.6rem",
              }}
            >
              Kalapay
            </span>
          </div>
        </Link>
        <nav className={`hidden lg:flex space-x-8 ${textColorClass}`}>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={linkVariants}
            transition={{ duration: 0.4 }}
          >
            <NavLink
              to="/"
              className={({ isActive }) =>
                `transition-colors duration-300 ease-in-out relative ${textColorClass} ${
                  isActive ? "font-bold text-yellow-600" : ""
                }`
              }
            >
              <span className="hover-underline-animation">Home</span>
            </NavLink>
          </motion.div>
          <motion.div
            initial="hidden"
            animate="visible"
            variants={linkVariants}
            transition={{ duration: 0.4, delay: 0.1 }}
          >
            <NavLink
              to="/about"
              className={({ isActive }) =>
                `transition-colors duration-300 ease-in-out relative ${textColorClass} ${
                  isActive ? "font-bold text-blue-600" : ""
                }`
              }
            >
              <span className="hover-underline-animation">About</span>
            </NavLink>
          </motion.div>
          {/* <motion.div
            initial="hidden"
            animate="visible"
            variants={linkVariants}
            transition={{ duration: 0.4, delay: 0.2 }}
          >
            <NavLink
              to="/categories"
              className={({ isActive }) =>
                `transition-colors duration-300 ease-in-out relative ${textColorClass} ${
                  isActive ? "font-bold text-blue-600" : ""
                }`
              }
            >
              <span className="hover-underline-animation">Categories</span>
            </NavLink>
          </motion.div> */}
          <motion.div
            initial="hidden"
            animate="visible"
            variants={linkVariants}
            transition={{ duration: 0.4, delay: 0.3 }}
          >
            <NavLink
              to="/contact"
              className={({ isActive }) =>
                `transition-colors duration-300 ease-in-out relative ${textColorClass} ${
                  isActive ? "font-bold text-blue-600" : ""
                }`
              }
            >
              <span className="hover-underline-animation">Contact</span>
            </NavLink>
          </motion.div>
          {user ? (
            <Dropdown overlay={dropdownMenu} trigger={["click"]}>
              <div
                className={`flex items-center ${textColorClass} cursor-pointer`}
              >
                <AiOutlineUser className="mr-1" />
                <span>{user.username}</span>
                <AiOutlineDown className="ml-1" />
              </div>
            </Dropdown>
          ) : (
            <motion.div
              initial="hidden"
              animate="visible"
              variants={linkVariants}
              transition={{ duration: 0.4, delay: 0.4 }}
            >
              <NavLink
                to="/login"
                className={({ isActive }) =>
                  `transition-colors duration-300 ease-in-out relative ${textColorClass} ${
                    isActive ? "font-bold text-blue-600" : ""
                  }`
                }
              >
                <span className="hover-underline-animation">Login</span>
              </NavLink>
            </motion.div>
          )}
        </nav>
        <div className="flex items-center space-x-4">
          <div className={`cursor-pointer flex relative ${textColorClass}`}>
            <Link to="/wishlist">
              <AiFillHeart style={{ fontSize: "1.7rem", marginTop: "0px" }} />
            </Link>
            <div
              className=" text-white absolute -right-1 -bottom-1.5 text-[12px] w-[18px] h-[18px] rounded-full flex justify-center items-center"
              style={{ backgroundColor: "#74512d" }}
            >
              {wishlist.length}
            </div>
          </div>
          <div
            className={`cursor-pointer flex relative ${textColorClass}`}
            onClick={toggleSidebar}
          >
            <BsBag className="text-2xl" />
            <div
              className="text-white absolute -right-2 -bottom-2 text-[12px] w-[18px] h-[18px] rounded-full flex justify-center items-center"
              style={{ backgroundColor: "#74512d" }}
            >
              {itemAmount}
            </div>
          </div>
          {!isMobile && <SearchIcon />}
        </div>
      </div>

      {isMenuOpen && <div className="overlay" onClick={handleMenuToggle}></div>}
      <div className={`lg:hidden mobile-nav ${isMenuOpen ? "open" : ""}`}>
        <button className="close-btn" onClick={handleMenuToggle}>
          <AiOutlineClose
            className={`text-2xl  ${textColorClass}`}
            style={{ color: "white" }}
          />
        </button>
        <div
          className="px-4 py-4"
          style={{
            display: "flex",
            alignItems: "center",
            color: "white ",
          }}
        >
          <SearchIcon />
        </div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={linkVariants}
          transition={{ duration: 0.4 }}
          className="w-full"
          style={{ borderTop: "1px solid white" }}
        >
          <NavLink
            to="/"
            className={({ isActive }) =>
              `block px-4 py-4 hover:bg-gray-700 transition-colors duration-300 ease-in-out mobile-navlink border-t border-gray-300 ${
                isActive ? "font-bold text-blue-600" : ""
              }`
            }
            onClick={handleMenuToggle}
          >
            Home
          </NavLink>
        </motion.div>
        <motion.div
          initial="hidden"
          animate="visible"
          variants={linkVariants}
          transition={{ duration: 0.4, delay: 0.1 }}
          className="w-full"
        >
          <NavLink
            to="/about"
            className={({ isActive }) =>
              `block px-4 py-4 hover:bg-gray-700 transition-colors duration-300 ease-in-out mobile-navlink ${
                isActive ? "font-bold text-blue-600" : ""
              }`
            }
            onClick={handleMenuToggle}
          >
            About
          </NavLink>
        </motion.div>
        {/* <motion.div
          initial="hidden"
          animate="visible"
          variants={linkVariants}
          transition={{ duration: 0.4, delay: 0.2 }}
          className="w-full"
        >
          <NavLink
            to="/categories"
            className={({ isActive }) =>
              `block px-4 py-4 hover:bg-gray-700 transition-colors duration-300 ease-in-out mobile-navlink ${
                isActive ? "font-bold text-blue-600" : ""
              }`
            }
            onClick={handleMenuToggle}
          >
            Categories
          </NavLink>
        </motion.div> */}

        <motion.div
          initial="hidden"
          animate="visible"
          variants={linkVariants}
          transition={{ duration: 0.4, delay: 0.3 }}
          className="w-full"
        >
          <NavLink
            to="/contact"
            className={({ isActive }) =>
              `block px-4 py-4 hover:bg-gray-700 transition-colors duration-300 ease-in-out mobile-navlink ${
                isActive ? "font-bold text-blue-600" : ""
              }`
            }
            onClick={handleMenuToggle}
          >
            Contact
          </NavLink>
        </motion.div>
        {user ? (
          <>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={linkVariants}
              transition={{ duration: 0.4, delay: 0.4 }}
              className="w-full"
            >
              <span
                className="block px-4 py-4 mobile-navlink flex items-center justify-between"
                onClick={toggleDropdown}
              >
                Hello, {user.username} <AiOutlineDown />
              </span>
              <AnimatePresence>
                {isDropdownOpen && (
                  <motion.div
                    initial={{ opacity: 0, y: -20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -20 }}
                    className="bg-white rounded-md shadow-lg"
                  >
                    <div
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                      onClick={handleOrdersClick}
                    >
                      Orders
                    </div>
                    <div
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                      onClick={() => {
                        navigate("/account-settings");
                        setIsMenuOpen(false); // Close the menu on account settings click
                      }}
                    >
                      Change Password
                    </div>
                    <div
                      className="block px-4 py-2 text-gray-800 hover:bg-gray-200 cursor-pointer"
                      onClick={handleLogout}
                    >
                      Logout
                    </div>
                  </motion.div>
                )}
              </AnimatePresence>
            </motion.div>
            <motion.button
              onClick={() => {
                handleLogout();
                handleMenuToggle();
              }}
              initial="hidden"
              animate="visible"
              variants={linkVariants}
              transition={{ duration: 0.4, delay: 0.5 }}
              className="block w-full text-left px-4 py-4 hover:bg-black-700 transition-colors duration-300 ease-in-out mobile-navlink"
            >
              Logout
            </motion.button>
          </>
        ) : (
          <motion.div
            initial="hidden"
            animate="visible"
            variants={linkVariants}
            transition={{ duration: 0.4, delay: 0.4 }}
            className="w-full"
          >
            <NavLink
              to="/login"
              className={({ isActive }) =>
                `block px-4 py-4 hover:bg-gray-700 transition-colors duration-300 ease-in-out mobile-navlink ${
                  isActive ? "font-bold text-blue-600" : ""
                }`
              }
              onClick={handleMenuToggle}
            >
              Login
            </NavLink>
          </motion.div>
        )}
      </div>
    </header>
  );
};

export default Header;
