import React, { useEffect, useState } from "react";
import { Card, Row, Col, List, Typography, Button } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./OrderConfirmation.css";

const { Title, Text } = Typography;

const OrderConfirmation = () => {
  const [orderDetails, setOrderDetails] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchOrderDetails = async () => {
      try {
        const response = await axios.get(
          "http://kalapay.shop/KalapayAPIupload/orderDetails.php",
          {
            params: { orderId: "12345" }, // replace with actual order ID
          }
        );
        setOrderDetails(response.data);
      } catch (error) {
        console.error("Error fetching order details:", error);
      }
    };

    fetchOrderDetails();
  }, []);

  if (!orderDetails) {
    return <div>Loading...</div>;
  }

  const handleContinueShopping = () => {
    navigate("/shop");
  };

  return (
    <div className="order-confirmation-container">
      <Card className="order-confirmation-card">
        <Row justify="center" align="middle">
          <CheckCircleOutlined style={{ fontSize: "64px", color: "#52c41a" }} />
        </Row>
        <Row justify="center" align="middle">
          <Title level={2} style={{ marginTop: "16px" }}>
            Thank You for Your Purchase!
          </Title>
        </Row>
        <Row justify="center" align="middle">
          <Text>Your order has been placed successfully.</Text>
        </Row>

        <Row className="order-info-section">
          <Col span={24}>
            <Title level={4}>Order Details</Title>
            <List
              bordered
              dataSource={orderDetails.items}
              renderItem={(item) => (
                <List.Item>
                  <Text>
                    {item.name} - {item.quantity} x ${item.price}
                  </Text>
                </List.Item>
              )}
            />
          </Col>
        </Row>

        <Row className="order-summary-section">
          <Col span={12}>
            <Title level={5}>Order ID:</Title>
            <Text>{orderDetails.orderId}</Text>
          </Col>
          <Col span={12}>
            <Title level={5}>Order Total:</Title>
            <Text>${orderDetails.total}</Text>
          </Col>
        </Row>

        <Row className="customer-info-section">
          <Col span={24}>
            <Title level={4}>Customer Information</Title>
            <Text>Name: {orderDetails.customer.name}</Text>
            <br />
            <Text>Email: {orderDetails.customer.email}</Text>
            <br />
            <Text>Address: {orderDetails.customer.address}</Text>
          </Col>
        </Row>

        <Row justify="center" style={{ marginTop: "24px" }}>
          <Button type="primary" onClick={handleContinueShopping}>
            Continue Shopping
          </Button>
        </Row>
      </Card>
    </div>
  );
};

export default OrderConfirmation;
