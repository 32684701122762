import React, { useContext, useEffect, useState, useCallback } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BsPlus, BsEyeFill } from "react-icons/bs";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { Card, Button, notification } from "antd";
import { CartContext } from "../contexts/CartContext";
import { SidebarContext } from "../contexts/SidebarContext";
import { UserContext } from "../contexts/UserContext";
import { WishlistContext } from "../contexts/WishlistContext";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import "../components/Product.css";
import { formatPrice } from "../components/formatPrice";
import axios from "axios";
import { useRef } from "react";
const { Meta } = Card;

const Product = ({ product }) => {
  const { addToCart } = useContext(CartContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const { user } = useContext(UserContext);
  const { addToWishlist, removeFromWishlist } = useContext(WishlistContext);
  const navigate = useNavigate();
  const { id, image, category, title, price, sizes, stock } = product;

  const [isWishlisted, setIsWishlisted] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const fetchWishlistState = useCallback(async () => {
    if (user) {
      try {
        const response = await axios.get(
          "http://kalapay.shop/KalapayAPIupload/wishlist.php",
          {
            params: { user_id: user.id, product_id: id },
          }
        );
        setIsWishlisted(response.data.isInWishlist);
      } catch (error) {
        console.error("Error fetching wishlist state:", error);
      }
    } else {
      setIsWishlisted(false);
    }
  }, [user, id]);

  useEffect(() => {
    fetchWishlistState();
  }, [fetchWishlistState]);

  const notificationKey = useRef(null);

  const handleAddToCart = () => {
    const closeNotification = () => {
      if (notificationKey.current !== null) {
        notification.close(notificationKey.current);
      }
    };

    if (stock === 0) {
      notification.warning({
        message: "Out of Stock",
        description:
          "The product is out of stock. You can add it to your wishlist for notification when it becomes available.",
      });
      return;
    }

    if (sizes && sizes.length > 0) {
      navigate(`/product/${id}`);
      notification.warning({
        message: "Size Required",
        description: "Please select a size before adding to kalapay.",
      });
    } else {
      if (stock >= quantity) {
        addToCart({ ...product, quantity }, id, quantity);
        updateProductStock(id, -quantity);

        notificationKey.current = `notification_${id}_${new Date().getTime()}`;
        notification.success({
          message: "Product Added to Kalapay",
          description: (
            <div style={styles.notificationContainer}>
              <img src={image} alt={title} style={styles.productImage} />
              <div>
                <div>{title}</div>
                <div>{formatPrice(price)}</div>
              </div>
              <Button
                type="link"
                onClick={() => {
                  toggleSidebar();
                  notification.destroy(notificationKey.current);
                }}
              >
                View Kalapay
              </Button>
            </div>
          ),
          key: notificationKey.current,
          duration: 3,
        });
      } else {
        notification.warning({
          message: "Out of Stock",
          description:
            "The product is out of stock. You can add it to your wishlist for notification when it becomes available.",
        });
      }
    }
  };
  const updateProductStock = async (productId, quantityChange) => {
    try {
      await axios.post("http://kalapay.shop/KalapayAPIupload/updateStock.php", {
        product_id: productId,
        quantity_change: quantityChange,
      });
    } catch (error) {
      console.error("Error updating product stock:", error);
    }
  };

  const handleWishlistClick = async () => {
    if (!user) {
      navigate("/login");
      return;
    }
    try {
      if (isWishlisted) {
        await axios.delete(
          "http://kalapay.shop/KalapayAPIupload/wishlist.php",
          {
            data: { user_id: user.id, product_id: id },
          }
        );
        removeFromWishlist(id);
        setIsWishlisted(false);
        notification.error({
          message: "Removed",
          description: "Removed from wishlist",
        });
      } else {
        await axios.post("http://kalapay.shop/KalapayAPIupload/wishlist.php", {
          user_id: user.id,
          product_id: id,
        });
        addToWishlist(product);
        setIsWishlisted(true);
        notification.success({
          message: "Added",
          description: (
            <span>
              Added to wishlist{" "}
              <Button type="link" onClick={() => navigate("/wishlist")}>
                View Wishlist
              </Button>
            </span>
          ),
        });
      }
    } catch (error) {
      console.error("There was an error handling the wishlist request:", error);
    }
  };

  const [containerRef, isIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });

  return (
    <div
      ref={containerRef}
      className={`transition-transform duration-1000 ease-in-out ${
        isIntersecting
          ? "transform-none opacity-100"
          : "translate-y-10 opacity-0"
      }`}
      style={{ flex: "1 0 21%", margin: "10px" }} // Adjusting the flex property for responsive layout
    >
      <Card
        hoverable
        className="product-card"
        style={{ width: "100%", maxWidth: 240, margin: "0 auto" }}
        cover={
          <Link to={`/product/${id}`}>
            <div className="image-container">
              {stock === 0 && (
                <div className="no-stock-overlay">
                  <span>No Stock</span>
                </div>
              )}
              <img src={image} alt={title} className="product-image" />
            </div>
          </Link>
        }
        actions={[
          <Button
            onClick={handleAddToCart}
            icon={<BsPlus />}
            disabled={stock === 0}
          />,
          <Link to={`/product/${id}`}>
            <Button icon={<BsEyeFill />} />
          </Link>,
          <Button
            onClick={handleWishlistClick}
            icon={
              isWishlisted ? (
                <FaHeart style={{ color: "#EE4E4E" }} />
              ) : (
                <FaRegHeart style={{ color: "black" }} />
              )
            }
          />,
        ]}
      >
        <Meta
          title={<Link to={`/product/${id}`}>{title}</Link>}
          description={
            <>
              <div className="text-sm capitalize text-gray-500">{category}</div>
              <div className="font-semibold">{formatPrice(price)}</div>
            </>
          }
        />
      </Card>
    </div>
  );
};

const styles = {
  notificationContainer: {
    display: "flex",
    alignItems: "center",
  },
  productImage: {
    width: 50,
    height: 50,
    marginRight: 10,
  },
};
export default Product;
