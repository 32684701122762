import React from "react";
import { Link } from "react-router-dom";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import backgroundImage from "../img/heroCompressed.webp";
import "../components/Hero.css";

const Hero = ({ popularProductsRef }) => {
  const [containerRef1, isIntersecting1] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [containerRef2, isIntersecting2] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [containerRef3, isIntersecting3] = useIntersectionObserver({
    threshold: 0.1,
  });

  const handleScrollToProducts = (e) => {
    e.preventDefault();
    if (popularProductsRef && popularProductsRef.current) {
      popularProductsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <section
      className="hero-section h-[800px] py-24 bg-no-repeat bg-cover bg-center relative"
      style={{ backgroundImage: `url(${backgroundImage})` }}
    >
      <div className="container relative px-4 md:px-8 lg:px-16 mx-auto flex justify-between items-center h-full">
        <div className="flex flex-col justify-center">
          <div
            ref={containerRef1}
            className={`transition-transform duration-2000 ease-in-out ${
              isIntersecting2
                ? "transform-none opacity-100"
                : "-translate-x-full opacity-0"
            }`}
          >
            <div className="font-semibold text-white flex items-center uppercase kalapay">
              <div className="w-10 h-[2px] bg-yellow-300 mr-3"></div>
              <p
                style={{
                  fontWeight: "500",
                  fontFamily: "jost",
                  fontSize: "1.3rem",
                }}
              >
                KALAPAY
              </p>
            </div>

            <h1
              className="text-[75px] leading-[1.1] font-light mb-4 text-white"
              style={{ fontFamily: "objectivity" }}
            >
              <span
                style={{
                  color: "#FFF2D7",

                  fontWeight: "500",
                  fontSize: "50px",
                }}
              >
                Authentic Ivatan Products: <br />
              </span>
              <span
                className=" "
                style={{
                  fontFamily: "raleway",
                  fontWeight: "700",
                  lineHeight: "0px",
                }}
              >
                <span style={{ color: "#F8F4E1" }}>
                  Handcrafted Treasures from Batanes
                </span>
              </span>
            </h1>
          </div>
        </div>
        <div
          ref={containerRef2}
          className={`transition-transform duration-1000 ease-in-out ${
            isIntersecting2
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <div className="hidden lg:block">
            {/* <img src={ivatanWoman} /> */}
          </div>
        </div>
        <div
          ref={containerRef3}
          className={`transition-transform duration-2000 ease-in-out ${
            isIntersecting3
              ? "transform-none opacity-100"
              : "-translate-x-full opacity-0"
          }`}
        >
          <div className="flex justify-end">
            <a
              href="#"
              onClick={handleScrollToProducts}
              className="hero-link"
              style={{ fontSize: "20px" }}
            >
              <span>I-KALAPAY MU NA!</span>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Hero;
