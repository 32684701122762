import { Link } from "react-router-dom";

const ViewAllLink = ({ category }) => {
  return (
    <Link
      to={`/category/${category}`}
      className="view-all-link"
      style={{
        display: "flex",
        justifyContent: "center",
      }}
    >
      View All
    </Link>
  );
};

export default ViewAllLink;
