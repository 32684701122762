import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import styled from "styled-components";
import cover from "../img/coverCoverCompressed.webp";
import { useAuth } from "../AuthContext";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f1e5d1;
  padding: 20px;
`;

const Card = styled(motion.div)`
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #803d3b;

  img {
    width: 410px;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    order: -1;
  }
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  padding-bottom: 15px;
  color: #6f4e37;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledForm = styled(Form)`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const LoginButton = styled(Button)`
  width: 100%;
  background-color: #74512d;
  color: white;
  &:hover {
    background-color: #af8260 !important;
  }
`;

const RegisterLink = styled(Link)`
  display: block;
  margin-top: 10px;
  text-align: center;
  color: #74512d;
  &:hover {
    text-decoration: underline;
  }
`;

const AdminLogin = () => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { setIsAuthenticated } = useAuth();

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/admin_login.php",
        values
      );
      if (response.data.success) {
        message.success("Admin logged in successfully");
        setIsAuthenticated(true);
        navigate("/admin", { replace: true });
      } else {
        message.error(response.data.message || "Login failed");
      }
    } catch (error) {
      message.error("Login failed: " + error.message);
    }
    setLoading(false);
  };

  return (
    <Container>
      <Card
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        style={{ marginTop: "80px" }}
      >
        <FormContainer>
          <Title
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Admin - Log in
          </Title>

          <StyledForm
            form={form}
            name="admin_login"
            onFinish={onFinish}
            layout="vertical"
            initialValues={{ remember: true }}
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your email!" }]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your password!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <LoginButton type="primary" htmlType="submit" loading={loading}>
                Log in
              </LoginButton>
            </Form.Item>
          </StyledForm>
        </FormContainer>
        <ImageContainer>
          <img src={cover} alt="Cover" />
        </ImageContainer>
      </Card>
    </Container>
  );
};

export default AdminLogin;
