import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";
import { Helmet } from "react-helmet-async";
import "../pages/CategoryPage.css";

const CategoryPage = () => {
  const { category } = useParams();
  const { products } = useContext(ProductContext);
  const [filteredProducts, setFilteredProducts] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (products) {
      const filtered = products.filter(
        (product) => product.subcategory === category
      );
      setFilteredProducts(filtered);
    }
  }, [products, category]);

  if (!products) {
    return <p>Loading products...</p>;
  }

  return (
    <div className="category-page" style={{ paddingTop: "80px" }}>
      <style>
        {`
          .ant-card-body{
            padding-top: 0 !important;
          }
        `}
      </style>
      <Helmet>
        <title>{category} Products - Kalapay Shop</title>
        <meta
          name="description"
          content={`Explore our collection of ${category} products at Kalapay Shop.`}
        />
        <link
          rel="canonical"
          href={`http://kalapay.shop/category/${category}`}
        />
      </Helmet>
      <h1 className="category-title">{category} Products</h1>
      <div className="products-container">
        {filteredProducts.length > 0 ? (
          filteredProducts.map((product) => (
            <Product key={product.id} product={product} />
          ))
        ) : (
          <p>No products available in this category.</p>
        )}
      </div>
    </div>
  );
};

export default CategoryPage;
