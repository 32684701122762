import React, { createContext, useState, useEffect, useContext } from "react";
import axios from "axios";
import { UserContext } from "./UserContext";

export const CartContext = createContext();

const CartProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const [cart, setCart] = useState([]);
  const [itemAmount, setItemAmount] = useState(0);
  const [total, setTotal] = useState(0);

  useEffect(() => {
    if (user) {
      fetchCartItems();
    } else {
      const storedCart = localStorage.getItem("cart");
      if (storedCart) {
        setCart(JSON.parse(storedCart));
      }
    }
  }, [user]);

  useEffect(() => {
    calculateCart();
    if (!user) {
      localStorage.setItem("cart", JSON.stringify(cart));
    }
  }, [cart, user]);

  const fetchCartItems = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/get_cart.php",
        {
          params: { user_id: user.id },
        }
      );
      if (response.data.success && Array.isArray(response.data.cartItems)) {
        setCart(response.data.cartItems);
      } else {
        console.error("Failed to fetch cart items: Invalid response format");
      }
    } catch (error) {
      console.error("Error fetching cart items:", error);
    }
  };

  const calculateCart = () => {
    const newTotal = cart.reduce((accumulator, currentItem) => {
      return accumulator + currentItem.price * Number(currentItem.quantity);
    }, 0);
    setTotal(newTotal || 0);

    const newAmount = cart.reduce((accumulator, currentItem) => {
      return accumulator + Number(currentItem.quantity);
    }, 0);
    setItemAmount(newAmount || 0);
  };

  const addToCart = async (product, id, quantity = 1) => {
    if (product.stock === 0) {
      console.error("Cannot add item to cart: Out of stock");
      return;
    }

    const userId = user?.id;
    try {
      const existingItem = cart.find((item) => item.id === id);
      if (existingItem) {
        const updatedQuantity = Number(existingItem.quantity) + quantity;
        if (updatedQuantity > product.stock) {
          console.error("Cannot add item to cart: Not enough stock");

          return;
        }
        if (user) {
          const response = await axios.put(
            "http://kalapay.shop/KalapayAPIupload/update_cart.php",
            {
              user_id: userId,
              product_id: id,
              quantity: updatedQuantity,
            }
          );
          if (!response.data.success) {
            console.error("Failed to update cart item:", response.data.error);
            return;
          }
        }
        const updatedCart = cart.map((item) =>
          item.id === id ? { ...item, quantity: updatedQuantity } : item
        );
        setCart(updatedCart);
      } else {
        if (quantity > product.stock) {
          console.error("Cannot add item to cart: Not enough stock");
          return;
        }
        if (user) {
          const response = await axios.post(
            "http://kalapay.shop/KalapayAPIupload/add_to_cart.php",
            {
              user_id: userId,
              product_id: id,
              quantity: quantity,
            }
          );
          if (!response.data.success) {
            console.error("Failed to add item to cart:", response.data.error);
            return;
          }
        }
        const newItem = { ...product, quantity: quantity };
        setCart([...cart, newItem]);
      }
      await updateProductStock(id, -quantity); // Decrease stock by the quantity added to cart
      if (!user) localStorage.setItem("cart", JSON.stringify(cart));
    } catch (error) {
      console.error("Error updating cart:", error);
    }
  };

  const removeFromCart = async (productId) => {
    const userId = user?.id;
    try {
      const item = cart.find((item) => item.id === productId);
      if (user) {
        const response = await axios.post(
          "http://kalapay.shop/KalapayAPIupload/delete_from_cart.php",
          {
            user_id: userId,
            product_id: productId,
          }
        );
        if (!response.data.success) {
          console.error(
            "Failed to remove item from cart:",
            response.data.error
          );
          return;
        }
      }
      const updatedCart = cart.filter((item) => item.id !== productId);
      setCart(updatedCart);
      await updateProductStock(productId, item.quantity);
      if (!user) localStorage.setItem("cart", JSON.stringify(updatedCart));
    } catch (error) {
      console.error("Error removing item from cart:", error);
    }
  };

  const clearCart = async () => {
    try {
      if (user) {
        await axios.delete(
          "http://kalapay.shop/KalapayAPIupload/clear_cart.php",
          {
            data: { user_id: user.id },
          }
        );
      }
      const stockUpdates = cart.map(
        (item) => updateProductStock(item.id, item.quantity) // Increase stock by the quantity of each item in the cart
      );
      await Promise.all(stockUpdates);
      setCart([]);
      localStorage.removeItem("cart");
    } catch (error) {
      console.error("Failed to clear cart:", error);
    }
  };

  const increaseAmount = async (id) => {
    const userId = user?.id;
    const existingItem = cart.find((item) => item.id === id);
    if (existingItem) {
      try {
        const updatedQuantity = Number(existingItem.quantity) + 1;
        if (user) {
          const response = await axios.put(
            "http://kalapay.shop/KalapayAPIupload/update_cart.php",
            {
              user_id: userId,
              product_id: id,
              quantity: updatedQuantity,
            }
          );
          if (!response.data.success) {
            console.error("Failed to update cart item:", response.data.error);
            return;
          }
        }
        const updatedCart = cart.map((item) =>
          item.id === id ? { ...item, quantity: updatedQuantity } : item
        );
        setCart(updatedCart);
        await updateProductStock(id, -1); // Decrease stock by 1
        if (!user) localStorage.setItem("cart", JSON.stringify(updatedCart));
      } catch (error) {
        console.error("Error updating cart item:", error);
      }
    }
  };

  const decreaseAmount = async (id) => {
    const userId = user?.id;
    const existingItem = cart.find((item) => item.id === id);
    if (existingItem && Number(existingItem.quantity) > 1) {
      try {
        const updatedQuantity = Number(existingItem.quantity) - 1;
        if (user) {
          const response = await axios.put(
            "http://kalapay.shop/KalapayAPIupload/update_cart.php",
            {
              user_id: userId,
              product_id: id,
              quantity: updatedQuantity,
            }
          );
          if (!response.data.success) {
            console.error("Failed to update cart item:", response.data.error);
            return;
          }
        }
        const updatedCart = cart.map((item) =>
          item.id === id ? { ...item, quantity: updatedQuantity } : item
        );
        setCart(updatedCart);
        await updateProductStock(id, 1); // Increase stock by 1
        if (!user) localStorage.setItem("cart", JSON.stringify(updatedCart));
      } catch (error) {
        console.error("Error updating cart item:", error);
      }
    } else if (existingItem && Number(existingItem.quantity) === 1) {
      removeFromCart(id);
    }
  };

  const updateProductStock = async (id, quantity) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/updateProductStock.php",
        {
          product_id: id,
          quantity: quantity,
        }
      );
      if (!response.data.success) {
        console.error("Failed to update product stock:", response.data.error);
      }
    } catch (error) {
      console.error("Error updating product stock:", error);
    }
  };

  const updateProductStocks = async (orderItems) => {
    try {
      const stockUpdates = orderItems.map((item) =>
        axios.post(
          "http://kalapay.shop/KalapayAPIupload/updateProductStock.php",
          {
            product_id: item.product_id,
            quantity: -item.quantity, // Deduct the quantity from the stock
          }
        )
      );
      await Promise.all(stockUpdates);
    } catch (error) {
      console.error("Error updating product stocks:", error);
    }
  };

  return (
    <CartContext.Provider
      value={{
        cart,
        setCart,
        addToCart,
        removeFromCart,
        clearCart,
        increaseAmount,
        decreaseAmount,
        itemAmount,
        total,
        updateProductStocks,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
