import React, { useContext, useRef, Suspense, useEffect } from "react";
import { ProductContext } from "../contexts/ProductContext";
import Product from "../components/Product";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import Slider from "react-slick";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { CustomPrevArrow, CustomNextArrow } from "../components/CustomArrows";
import "../components/Hero.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import welcomeImage from "../img/welcome.png";
import "../pages/Categories.css";

const Categories = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const { products } = useContext(ProductContext);

  const popularProductsRef = useRef(null);
  const miniatureProductsRef = useRef(null);
  const footwearProductsRef = useRef(null);
  const handicraftProductsRef = useRef(null);
  const accessoriesProductsRef = useRef(null);

  const [welcomeSectionRef, isWelcomeIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [bannerSectionRef, isBannerIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [popularProductsContainerRef, isPopularIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });
  const [miniatureProductsContainerRef, isMiniatureIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });
  const [footwearProductsContainerRef, isFootwearIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });
  const [handicraftProductsContainerRef, isHandicraftIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const [accessoriesProductsContainerRef, isAccessoriesIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const navigate = useNavigate();

  if (!products) {
    return <p>Loading products...</p>;
  }

  const popularProducts = products.filter(
    (product) => product.subcategory === "Popular"
  );

  const miniatureProducts = products.filter(
    (product) => product.subcategory === "Miniature"
  );

  const footwearProducts = products.filter(
    (product) => product.subcategory === "Footwear"
  );

  const handicraftProducts = products.filter(
    (product) => product.subcategory === "Handicraft"
  );

  const accessoriesProducts = products.filter(
    (product) => product.subcategory === "Accessories"
  );

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 4,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <div className="section-container">
      <style>
        {`
          .ant-card-body{
    padding-top: 0 !important;
}
        `}
      </style>
      <section
        ref={miniatureProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={miniatureProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isMiniatureIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;MINIATURE
          </h1>
        </div>
        <div className="container">
          {miniatureProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {miniatureProducts.map((product) => (
                <div key={product.id} className="p-2 gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {miniatureProducts.length > 0 ? (
                miniatureProducts.map((product) => (
                  <div key={product.id} className="p-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Footwear products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      <section
        ref={footwearProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={footwearProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isFootwearIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;FOOTWEAR
          </h1>
        </div>
        <div className="container">
          {footwearProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {footwearProducts.map((product) => (
                <div key={product.id} className="p-2 gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {footwearProducts.length > 0 ? (
                footwearProducts.map((product) => (
                  <div key={product.id} className="p-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Footwear products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      {/* HANDICRAAAAAAFTTTT*/}

      <section
        ref={handicraftProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={handicraftProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isHandicraftIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;HANDICRAFT
          </h1>
        </div>
        <div className="container">
          {handicraftProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {handicraftProducts.map((product) => (
                <div key={product.id} className="p-2 gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {handicraftProducts.length > 0 ? (
                handicraftProducts.map((product) => (
                  <div key={product.id} className="p-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Handicraft products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      {/* ACCESSORIES*/}

      <section
        ref={accessoriesProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={accessoriesProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isAccessoriesIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;ACCESSORIES
          </h1>
        </div>
        <div className="container">
          {accessoriesProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {accessoriesProducts.map((product) => (
                <div key={product.id} className="p-2 gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {accessoriesProducts.length > 0 ? (
                accessoriesProducts.map((product) => (
                  <div key={product.id} className="p-2">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Accessories products available.</p>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Categories;
