import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import {
  Table,
  Tag,
  message,
  List,
  Avatar,
  Card,
  Typography,
  Button,
  Popconfirm,
  Input,
} from "antd";
import { format } from "date-fns";
import { UserContext } from "../contexts/UserContext";
import { formatPrice } from "../components/formatPrice";
import { DeleteOutlined, UpOutlined, DownOutlined } from "@ant-design/icons";

const { Title } = Typography;
const { Search } = Input;

const Orders = () => {
  const { user, loading: userLoading } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedFilter, setSelectedFilter] = useState("all");
  const handleFilterChange = (filter) => {
    setSelectedFilter(filter);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    if (userLoading) return;

    if (user) {
      fetchOrders(user.id);
      const intervalId = setInterval(() => fetchOrders(user.id), 3000);
      return () => clearInterval(intervalId);
    } else {
      message.error("User not logged in");
      setLoading(false);
    }
  }, [user, userLoading]);

  const fetchOrders = async (userId) => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/getOrders.php",
        {
          params: { user_id: userId },
        }
      );
      const sortedOrders = response.data
        .filter((order) => order.status !== "removed") // Exclude removed orders
        .sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      setOrders(sortedOrders);
    } catch (error) {
      console.error("Failed to fetch orders", error);
      message.error("Failed to fetch orders");
    } finally {
      setLoading(false);
    }
  };

  const cancelOrder = async (orderId) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/cancelOrder.php",
        {
          order_id: orderId,
        }
      );

      if (
        response.status === 200 &&
        response.data.message === "Order status updated to canceled"
      ) {
        message.success("Order canceled successfully");
        setOrders((prevOrders) =>
          prevOrders.map((order) =>
            order.id === orderId ? { ...order, status: "canceled" } : order
          )
        );
      } else {
        message.error("Failed to cancel order");
      }
    } catch (error) {
      console.error("Failed to cancel order", error);
      message.error("Failed to cancel order");
    }
  };

  const deleteOrder = async (orderId) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/removeFromList.php",
        {
          order_id: orderId,
          status: "removed",
        }
      );

      if (response.status === 200 && response.data.success) {
        message.success("Order removed successfully");
        setOrders((prevOrders) =>
          prevOrders.filter((order) => order.id !== orderId)
        );
      } else {
        message.error("Failed to remove order");
      }
    } catch (error) {
      console.error("Failed to remove order", error);
      message.error("Failed to remove order");
    }
  };

  const formatDate = (dateString) =>
    format(new Date(dateString), "MMM dd, yyyy hh:mm a");

  const columns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Delivery Option",
      dataIndex: "delivery_option",
      key: "delivery_option",
      sorter: (a, b) => a.delivery_option.localeCompare(b.delivery_option),
    },
    {
      title: "Status",
      key: "status",
      dataIndex: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status) => {
        let color;
        switch (status) {
          case "completed":
            color = "green";
            break;
          case "pending":
            color = "blue";
            break;
          case "canceled":
            color = "red";
            break;
          case "processing":
            color = "orange";
            break;
          case "ready_to_pickup":
            color = "teal";
            break;
          case "order_in_transit":
            color = "teal";
            break;
          default:
            color = "gray";
        }
        return <Tag color={color}>{status.toUpperCase()}</Tag>;
      },
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
      render: (total) => formatPrice(total),
    },
    {
      title: "Ordered At",
      dataIndex: "created_at",
      key: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (created_at) => formatDate(created_at),
    },
    {
      title: "Action",
      key: "action",
      render: (_, record) => {
        if (record.status === "pending") {
          return (
            <Popconfirm
              title="Are you sure you want to cancel this order?"
              onConfirm={() => cancelOrder(record.id)}
              okText="Yes"
              cancelText="No"
            >
              <Button
                type="primary"
                danger
                style={{ color: "#cf1322", backgroundColor: "#fff1f0" }}
              >
                Cancel Order
              </Button>
            </Popconfirm>
          );
        } else if (record.status === "canceled") {
          return (
            <Button
              type="primary"
              danger
              style={{ color: "#cf1322", backgroundColor: "#fff1f0" }}
              icon={<DeleteOutlined />}
              onClick={() => deleteOrder(record.id)}
            >
              Remove
            </Button>
          );
        } else {
          return null;
        }
      },
    },
  ];

  const expandedRowRender = (record) => (
    <List
      itemLayout="horizontal"
      dataSource={record.order_items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={item.image} />}
            title={item.title}
            description={`Quantity: ${item.quantity} - ${formatPrice(
              item.price
            )} each`}
          />
          <div>{formatPrice(item.price * item.quantity)}</div>
        </List.Item>
      )}
    />
  );

  const renderMobileCard = (order) => (
    <Card key={order.id} style={{ marginBottom: "20px" }}>
      <Title level={4}>Order ID: {order.id}</Title>
      <p>Total: {formatPrice(order.total)}</p>
      <p>Delivery Option: {order.delivery_option}</p>
      <p>
        Status:{" "}
        <Tag color={getStatusColor(order.status)}>
          {order.status.toUpperCase()}
        </Tag>
      </p>
      <p>Created At: {formatDate(order.created_at)}</p>
      <List
        itemLayout="horizontal"
        dataSource={order.order_items}
        renderItem={(item) => (
          <List.Item>
            <List.Item.Meta
              avatar={<Avatar src={item.image} />}
              title={item.title}
              description={`Quantity: ${item.quantity} - ${formatPrice(
                item.price
              )} each`}
            />
            <div>{formatPrice(item.price * item.quantity)}</div>
          </List.Item>
        )}
      />
      {order.status === "pending" && (
        <Popconfirm
          title="Are you sure you want to cancel this order?"
          onConfirm={() => cancelOrder(order.id)}
          okText="Yes"
          cancelText="No"
        >
          <Button
            type="primary"
            danger
            style={{ color: "#cf1322", backgroundColor: "#fff1f0" }}
          >
            Cancel Order
          </Button>
        </Popconfirm>
      )}
      {order.status === "canceled" && (
        <Button
          type="primary"
          danger
          style={{ color: "#cf1322", backgroundColor: "#fff1f0" }}
          icon={<DeleteOutlined />}
          onClick={() => deleteOrder(order.id)}
        >
          Remove
        </Button>
      )}
    </Card>
  );

  const getStatusColor = (status) => {
    switch (status) {
      case "completed":
        return "green";
      case "pending":
        return "blue";
      case "processing":
        return "orange";
      case "canceled":
        return "red";
      case "ready_to_pickup":
        return "teal";
      case "order_in_transit":
        return "teal";
      default:
        return "gray";
    }
  };
  let filteredOrdersByStatus = orders;

  if (selectedFilter === "pending") {
    filteredOrdersByStatus = orders.filter(
      (order) => order.status === "pending"
    );
  } else if (selectedFilter === "completed") {
    filteredOrdersByStatus = orders.filter(
      (order) => order.status === "completed"
    );
  } else if (selectedFilter === "processing") {
    filteredOrdersByStatus = orders.filter(
      (order) => order.status === "processing"
    );
  } else if (selectedFilter === "canceled") {
    filteredOrdersByStatus = orders.filter(
      (order) => order.status === "canceled"
    );
  } else if (selectedFilter === "all") {
    filteredOrdersByStatus = orders.filter(
      (order) => order.status !== "canceled"
    );
  }

  const filteredOrders = filteredOrdersByStatus.filter(
    (order) =>
      order.id.toString().includes(searchQuery) ||
      order.delivery_option.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.status.toLowerCase().includes(searchQuery.toLowerCase()) ||
      order.total.toString().includes(searchQuery) ||
      formatDate(order.created_at)
        .toLowerCase()
        .includes(searchQuery.toLowerCase())
  );

  return (
    <div
      style={{ paddingTop: "80px", paddingLeft: "20px", paddingRight: "20px" }}
    >
      <h1 style={{ fontSize: "2.2rem", fontWeight: "600" }}>My Orders</h1>
      <Search
        placeholder="Search orders"
        onChange={(e) => setSearchQuery(e.target.value)}
        style={{ marginBottom: "20px" }}
      />
      <div style={{ marginBottom: "20px" }}>
        <Button onClick={() => handleFilterChange("all")}>All Orders</Button>
        <Button onClick={() => handleFilterChange("pending")}>Pending</Button>
        <Button onClick={() => handleFilterChange("processing")}>
          Processing
        </Button>
        <Button onClick={() => handleFilterChange("completed")}>
          Completed
        </Button>
        <Button onClick={() => handleFilterChange("canceled")}>Canceled</Button>
      </div>
      <div className="order-table-wrapper">
        <Table
          columns={columns}
          dataSource={filteredOrders}
          loading={loading}
          rowKey="id"
          expandable={{
            expandedRowRender: (record) => (
              <List
                itemLayout="horizontal"
                dataSource={record.order_items}
                renderItem={(item) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={<Avatar src={item.image} />}
                      title={item.title}
                      description={`Quantity: ${item.quantity} - ${formatPrice(
                        item.price
                      )} each`}
                    />
                    <div>{formatPrice(item.price * item.quantity)}</div>
                  </List.Item>
                )}
              />
            ),
            expandIcon: ({ expanded, onExpand, record }) => (
              <div style={{ display: "flex", alignItems: "center" }}>
                <span
                  style={{
                    marginRight: "8px",
                    fontSize: "0.8rem",
                    fontFamily: "Raleway",
                  }}
                >
                  View Items
                </span>
                <a
                  style={{ marginLeft: "auto" }}
                  onClick={(e) => onExpand(record, e)}
                >
                  {expanded ? <UpOutlined /> : <DownOutlined />}
                </a>
              </div>
            ),
          }}
          pagination={{ pageSize: 15 }}
          className="order-table"
          scroll={{ x: true }}
        />
      </div>
      <div className="order-mobile-cards">
        {filteredOrders.map(renderMobileCard)}
      </div>
      <style jsx>{`
        .order-table-wrapper {
          display: none;
        }
        .order-mobile-cards {
          display: block;
        }
        @media (min-width: 768px) {
          .order-table-wrapper {
            display: block;
          }
          .order-mobile-cards {
            display: none;
          }
        }
      `}</style>
    </div>
  );
};

export default Orders;
