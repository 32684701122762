import React, { useState, useEffect, useRef } from "react";
import { FiSearch } from "react-icons/fi";
import { useMediaQuery } from "react-responsive";
import { Input } from "antd";

import "./SearchIcon.css";

const { Search } = Input;

const SearchIcon = () => {
  //   const [isActive, setIsActive] = useState(false);
  //   const [isSearchOpen, setIsSearchOpen] = useState(false);
  //   const isMobile = useMediaQuery({ maxWidth: 767 });
  //   const searchContainerRef = useRef(null);
  //   useEffect(() => {
  //     const handleScroll = () => {
  //       setIsActive(window.scrollY > 60);
  //     };
  //     const handleResize = () => {
  //       if (window.innerWidth >= 60) {
  //         window.addEventListener("scroll", handleScroll);
  //       } else {
  //         window.removeEventListener("scroll", handleScroll);
  //       }
  //     };
  //     handleResize();
  //     window.addEventListener("resize", handleResize);
  //     return () => {
  //       window.removeEventListener("resize", handleResize);
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);
  //   const handleSearchIconClick = () => {
  //     setIsSearchOpen(!isSearchOpen);
  //   };
  //   const handleOutsideClick = (event) => {
  //     if (
  //       searchContainerRef.current &&
  //       !searchContainerRef.current.contains(event.target)
  //     ) {
  //       setIsSearchOpen(false);
  //     }
  //   };
  //   useEffect(() => {
  //     document.addEventListener("click", handleOutsideClick);
  //     return () => {
  //       document.removeEventListener("click", handleOutsideClick);
  //     };
  //   }, []);
  //   const textColorClass = isMobile
  //     ? "text-white"
  //     : isActive
  //     ? "text-black"
  //     : "text-white";
  //   return (
  //     <div className="relative search-container" ref={searchContainerRef}>
  //       {isMobile ? (
  //         <Search
  //           placeholder="Search..."
  //           enterButton
  //           className="w-full custom-search"
  //           onSearch={(value) => console.log(value)}
  //         />
  //       ) : (
  //         <button
  //           className={`p-2 rounded-full transition-colors duration-300 ${textColorClass}`}
  //           onClick={handleSearchIconClick}
  //           style={{ fontSize: "1.5rem", marginTop: "3px" }}
  //         >
  //           <FiSearch />
  //         </button>
  //       )}
  //       {isSearchOpen && !isMobile && (
  //         <div
  //           className={`absolute right-0 mt-2 w-64 ${
  //             isMobile ? "left-0 ml-4" : ""
  //           }`}
  //         >
  //           <Search
  //             placeholder="Search..."
  //             enterButton
  //             className="w-full custom-search"
  //             onSearch={(value) => console.log(value)}
  //           />
  //         </div>
  //       )}
  //     </div>
  //   );
};

export default SearchIcon;
