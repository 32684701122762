import React, { useEffect, useState, useContext } from "react";
import { List, Card, Button, notification, Tooltip, Spin, Modal } from "antd";
import { ShoppingCartOutlined, DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { SidebarContext } from "../contexts/SidebarContext";
import { UserContext } from "../contexts/UserContext";
import { WishlistContext } from "../contexts/WishlistContext";
import { CartContext } from "../contexts/CartContext";
import { formatPrice } from "../components/formatPrice";
import { FaRegHeart, FaHeart } from "react-icons/fa";
import { motion } from "framer-motion";
import "./Wishlist.css";

const Wishlist = () => {
  const { user } = useContext(UserContext);
  const { toggleSidebar } = useContext(SidebarContext);
  const { wishlist, removeFromWishlist, setWishlist } =
    useContext(WishlistContext);
  const { addToCart } = useContext(CartContext);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalImage, setModalImage] = useState("");

  useEffect(() => {
    const handleClick = () => {
      window.scrollTo({ top: 0 });
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    const fetchWishlistItems = async () => {
      if (user) {
        setLoading(true);
        try {
          const response = await axios.get(
            "http://kalapay.shop/KalapayAPIupload/wishlist.php",
            {
              params: { user_id: user.id },
            }
          );
          setWishlist(response.data);
        } catch (error) {
          console.error("Error fetching wishlist items:", error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchWishlistItems();
  }, [user, setWishlist]);

  const handleAddToCart = (item) => {
    if (item.stock > 0) {
      addToCart(item, item.id);
      toggleSidebar();
    } else {
      notification.warning({
        message: "Out of Stock",
        description: `${item.title} is out of stock and cannot be added to the cart.`,
      });
    }
  };

  const handleRemoveFromWishlist = async (item) => {
    try {
      await axios.delete("http://kalapay.shop/KalapayAPIupload/wishlist.php", {
        data: { user_id: user.id, product_id: item.id },
      });
      removeFromWishlist(item.id);
      notification.success({
        message: "Removed",
        description: `${item.title} removed from wishlist`,
      });
    } catch (error) {
      console.error("Error removing item from wishlist:", error);
    }
  };

  const showModal = (image) => {
    setModalImage(image);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <div className="wishlist-container">
      <motion.h2
        className="wishlist-title"
        style={{
          display: "flex",
          alignItems: "center",
          gap: "13px",
          justifyContent: "center",
        }}
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        My Wishlist <FaHeart style={{ color: "#EE4E4E" }} />{" "}
      </motion.h2>
      {loading ? (
        <div className="wishlist-spinner">
          <Spin size="large" />
        </div>
      ) : (
        <List
          grid={{
            gutter: 16,
            xs: 1,
            sm: 2,
            md: 2,
            lg: 3,
            xl: 3,
            xxl: 3,
          }}
          dataSource={wishlist}
          renderItem={(item) => (
            <List.Item>
              <motion.div
                initial={{ opacity: 0, y: 50 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5 }}
              >
                <Card
                  hoverable
                  cover={
                    <img
                      alt={item.title}
                      src={item.image}
                      className="wishlist-image"
                      onClick={() => showModal(item.image)}
                    />
                  }
                  className="wishlist-card"
                >
                  <Card.Meta
                    title={item.title}
                    description={
                      <>
                        <p
                          dangerouslySetInnerHTML={{ __html: item.description }}
                        />
                        <p className="wishlist-price">
                          {formatPrice(item.price)}
                        </p>
                      </>
                    }
                  />
                  <div className="wishlist-actions">
                    <Tooltip
                      title={item.stock > 0 ? "Add to Kalapay" : "Out of stock"}
                    >
                      <Button
                        shape="circle"
                        icon={<ShoppingCartOutlined />}
                        onClick={() => handleAddToCart(item)}
                        className="wishlist-button add-to-cart-button"
                      />
                    </Tooltip>
                    <Tooltip title="Remove">
                      <Button
                        type="danger"
                        shape="circle"
                        icon={<DeleteOutlined />}
                        onClick={() => handleRemoveFromWishlist(item)}
                        className="wishlist-button remove-button"
                      />
                    </Tooltip>
                  </div>
                </Card>
              </motion.div>
            </List.Item>
          )}
        />
      )}
      <Modal
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
        width={800}
        className="image-modal"
      >
        <img src={modalImage} alt="Full size" className="modal-image" />
      </Modal>
    </div>
  );
};

export default Wishlist;
