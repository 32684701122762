import React, {
  useContext,
  useRef,
  lazy,
  Suspense,
  useState,
  useEffect,
} from "react";
import { Helmet } from "react-helmet-async";
import { ProductContext } from "../contexts/ProductContext";
import Hero from "../components/Hero";
import Slider from "react-slick";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useIntersectionObserver from "../hooks/useIntersectionObserver";
import { CustomPrevArrow, CustomNextArrow } from "../components/CustomArrows";
import axios from "axios";
import "../components/Hero.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import welcomeImage from "../img/welcome.png";
import "../pages/Home.css";
import ViewAllLink from "../components/ViewAllLink";
const BannerSlider = lazy(() => import("../components/BannerSlider"));
const Product = lazy(() => import("../components/Product"));

const Home = () => {
  const { products } = useContext(ProductContext);
  const [popularProductIds, setPopularProductIds] = useState([]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    axios
      .get("http://kalapay.shop/KalapayAPIupload/getPopularOrders.php")
      .then((response) => setPopularProductIds(response.data))
      .catch((error) =>
        console.error("Error fetching popular products:", error)
      );
  }, []);

  const popularProductsRef = useRef(null);
  const miniatureProductsRef = useRef(null);
  const footwearProductsRef = useRef(null);
  const handicraftProductsRef = useRef(null);
  const accessoriesProductsRef = useRef(null);
  const [welcomeSectionRef, isWelcomeIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [bannerSectionRef, isBannerIntersecting] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [popularProductsContainerRef, isPopularIntersecting] =
    useIntersectionObserver({ threshold: 0.1 });
  const [miniatureProductsContainerRef, isMiniatureIntersecting] =
    useIntersectionObserver({ threshold: 0.1 });
  const [footwearProductsContainerRef, isFootwearIntersecting] =
    useIntersectionObserver({ threshold: 0.1 });
  const [handicraftProductsContainerRef, isHandicraftIntersecting] =
    useIntersectionObserver({ threshold: 0.1 });
  const [accessoriesProductsContainerRef, isAccessoriesIntersecting] =
    useIntersectionObserver({
      threshold: 0.1,
    });

  const navigate = useNavigate();

  if (!products) {
    return <p>Loading products...</p>;
  }
  const limitProducts = (products, limit) => {
    return products.slice(0, limit);
  };

  const popularProducts = limitProducts(
    products.filter((product) => popularProductIds.includes(product.id)),
    6
  );
  const miniatureProducts = limitProducts(
    products.filter((product) => product.subcategory === "Miniature"),
    6
  );
  const footwearProducts = limitProducts(
    products.filter((product) => product.subcategory === "Footwear"),
    6
  );
  const handicraftProducts = limitProducts(
    products.filter((product) => product.subcategory === "Handicraft"),
    6
  );
  const accessoriesProducts = limitProducts(
    products.filter((product) => product.subcategory === "Accessories"),
    6
  );
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    nextArrow: <CustomNextArrow />,
    prevArrow: <CustomPrevArrow />,
    responsive: [
      { breakpoint: 1200, settings: { slidesToShow: 4 } },
      { breakpoint: 992, settings: { slidesToShow: 3 } },
      { breakpoint: 768, settings: { slidesToShow: 2 } },
      { breakpoint: 576, settings: { slidesToShow: 1 } },
    ],
  };

  return (
    <div>
      <Helmet>
        <title>Kalapay Shop</title>
        <meta
          name="description"
          content="Welcome to Kalapay.shop, your destination for unique handcrafted products. Explore our popular, miniature, footwear, handicraft, and accessories collections."
        />
        <link rel="canonical" href="http://kalapay.shop/" />
      </Helmet>
      <style>
        {`
          .ant-card-body{
            padding-top: 0 !important;
          }
        `}
      </style>
      <Hero popularProductsRef={popularProductsRef} />
      <section
        ref={welcomeSectionRef}
        className={`welcome-section flex flex-col md:flex-row items-center justify-between p-4 md:p-8 transition-transform duration-700 ease-in-out ${
          isWelcomeIntersecting
            ? "transform-none opacity-100"
            : "translate-y-10 opacity-0"
        }`}
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div className="welcome-text max-w-md mb-4 md:mb-0 text-center md:text-left">
          <h1
            className="text-3xl md:text-4xl font-bold mb-4"
            style={{
              fontSize: "3rem",
              lineHeight: "3.3rem",
              fontFamily: "Raleway",
            }}
          >
            Welcome to Kalapay.shop
          </h1>
          <p
            className="text-base md:text-lg"
            style={{ fontFamily: "objectivity" }}
          >
            Kalapay, a woven basket for men with straps carried at the back
            knapsack-style, holds profound cultural significance among the
            Ivatan people of the Batanes Islands. Traditionally, it transcends
            mere utility, embodying craftsmanship, heritage, and identity...
          </p>
          <Button
            onClick={() => navigate("/about")}
            sx={{ mt: 2 }}
            className="readMore"
          >
            Read More
          </Button>
        </div>
        <div className="welcome-image-container w-full md:w-auto flex justify-center">
          <img
            src={welcomeImage}
            alt="Welcome"
            className="w-full md:w-auto h-auto object-cover rounded-lg"
          />
        </div>
      </section>
      <section
        ref={bannerSectionRef}
        className={`banner transition-transform duration-700 ease-in-out ${
          isBannerIntersecting
            ? "transform-none opacity-100"
            : "translate-y-10 opacity-0"
        }`}
      >
        <Suspense fallback={<div>Loading...</div>}>
          <BannerSlider />
        </Suspense>
      </section>

      <section
        ref={popularProductsRef}
        className="pb-10 py-5 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={popularProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isPopularIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            MOST POPULAR
          </h1>
        </div>
        <div className={`container ${popularProducts.length > 5 ? "" : "p-0"}`}>
          {popularProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {popularProducts.map((product) => (
                <div key={product.id} className=" gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {popularProducts.length > 0 ? (
                popularProducts.map((product) => (
                  <div key={product.id} className="">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Popular products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      <section
        ref={miniatureProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12 pt-5"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={miniatureProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isMiniatureIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;MINIATURE
          </h1>
          {miniatureProducts.length > 5 && <ViewAllLink category="Miniature" />}
        </div>
        <div
          className={`container ${miniatureProducts.length > 5 ? "" : "p-0"}`}
        >
          {miniatureProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {miniatureProducts.map((product) => (
                <div key={product.id} className=" gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {miniatureProducts.length > 0 ? (
                miniatureProducts.map((product) => (
                  <div key={product.id} className="">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Miniature products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      <section
        ref={footwearProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={footwearProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isFootwearIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;FOOTWEAR
          </h1>
          {footwearProducts.length > 5 && <ViewAllLink category="Footwear" />}
        </div>
        <div
          className={`container ${footwearProducts.length > 5 ? "" : "p-0"}`}
        >
          {footwearProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {footwearProducts.map((product) => (
                <div key={product.id} className=" gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {footwearProducts.length > 0 ? (
                footwearProducts.map((product) => (
                  <div key={product.id} className="">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Footwear products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      <section
        ref={handicraftProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={handicraftProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isHandicraftIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;HANDICRAFT
          </h1>
          {handicraftProducts.length > 5 && (
            <ViewAllLink category="Handicraft" />
          )}
        </div>
        <div
          className={`container ${handicraftProducts.length > 5 ? "" : "p-0"}`}
        >
          {handicraftProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {handicraftProducts.map((product) => (
                <div key={product.id} className=" gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {handicraftProducts.length > 0 ? (
                handicraftProducts.map((product) => (
                  <div key={product.id} className="">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Handicraft products available.</p>
              )}
            </div>
          )}
        </div>
      </section>

      {/* ACCESSORIES*/}

      <section
        ref={accessoriesProductsRef}
        className="pb-10 flex flex-col items-center px-4 md:px-12"
        style={{ backgroundColor: "#F6F5F2" }}
      >
        <div
          ref={accessoriesProductsContainerRef}
          className={`transition-transform duration-700 ease-in-out ${
            isAccessoriesIntersecting
              ? "transform-none opacity-100"
              : "translate-y-10 opacity-0"
          }`}
        >
          <h1
            style={{
              fontSize: "2.3rem",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
              fontFamily: "Raleway",
              textAlign: "center",
              margin: "0 auto",
              justifyContent: "center",
            }}
            className="mb-10 featured"
          >
            {/* <AutoAwesomeIcon style={{ fontSize: 36 }} /> */}
            &nbsp;ACCESSORIES
          </h1>
          {accessoriesProducts.length > 5 && (
            <ViewAllLink category="Accessories" />
          )}
        </div>
        <div
          className={`container ${accessoriesProducts.length > 5 ? "" : "p-0"}`}
        >
          {accessoriesProducts.length > 5 ? (
            <Slider {...sliderSettings}>
              {accessoriesProducts.map((product) => (
                <div key={product.id} className=" gap-4">
                  <Suspense fallback={<div>Loading...</div>}>
                    <Product product={product} />
                  </Suspense>
                </div>
              ))}
            </Slider>
          ) : (
            <div className="flex justify-center items-center flex-wrap gap-4 py-5">
              {accessoriesProducts.length > 0 ? (
                accessoriesProducts.map((product) => (
                  <div key={product.id} className="">
                    <Suspense fallback={<div>Loading...</div>}>
                      <Product product={product} />
                    </Suspense>
                  </div>
                ))
              ) : (
                <p>No Accessories products available.</p>
              )}
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default Home;
