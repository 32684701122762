import React, { useEffect } from "react";
import { Form, Input, Button, Row, Col, Typography, Modal } from "antd";
import {
  PhoneOutlined,
  MailOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import { motion } from "framer-motion";
import axios from "axios";
import "../pages/Contact.css";

const { Title, Paragraph } = Typography;

const ContactPage = () => {
  useEffect(() => {
    const handleClick = () => {
      window.scrollTo({ top: 0 });
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  const [form] = Form.useForm();

  const onFinish = (values) => {
    console.log("Success:", values);

    axios
      .post("http://kalapay.shop/KalapayAPIupload/submit_message.php", values, {
        headers: {
          "Content-Type": "application/json",
        },
      })
      .then((response) => {
        if (response.data.success) {
          Modal.success({
            content: "Message sent successfully",
            onOk: () => form.resetFields(),
          });
        } else {
          Modal.error({
            title: "Message sending failed",
            content: response.data.error,
          });
        }
      })
      .catch((error) => {
        console.error("There was an error sending the message!", error);
        Modal.error({
          title: "Message sending failed",
          content: "An error occurred while sending the message.",
        });
      });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
    Modal.error({
      title: "Validation Error",
      content: "Please check the form for errors.",
    });
  };
  const animationProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <div className="contact-page bg-f5f5dc min-h-screen p-8">
      <Row justify="center" align="middle" className="min-h-full">
        <Col
          xs={22}
          sm={18}
          md={12}
          lg={10}
          className="bg-fff8dc p-6 rounded-lg shadow-md"
        >
          <motion.div {...animationProps}>
            <Title level={2} className="text-8b4513">
              Contact Us
            </Title>
          </motion.div>
          <motion.div {...animationProps}>
            <Paragraph className="text-d2b48c">
              If you have any questions, feel free to reach out to us using the
              form below.
            </Paragraph>
          </motion.div>
          <motion.div {...animationProps}>
            <Form
              name="contact"
              layout="vertical"
              onFinish={onFinish}
              onFinishFailed={onFinishFailed}
              form={form}
            >
              <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: "Please input your name!" }]}
              >
                <Input placeholder="Your Name" className="border-deb887" />
              </Form.Item>
              <Form.Item
                name="email"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Please input your email!",
                    type: "email",
                  },
                ]}
              >
                <Input placeholder="Your Email" className="border-deb887" />
              </Form.Item>
              <Form.Item
                name="message"
                label="Message"
                rules={[
                  { required: true, message: "Please input your message!" },
                ]}
              >
                <Input.TextArea
                  rows={4}
                  placeholder="Your Message"
                  className="border-deb887"
                />
              </Form.Item>
              <Form.Item>
                <Button
                  htmlType="submit"
                  block
                  className="bg-8b4513 text-white hover:bg-d2b48c"
                >
                  Submit
                </Button>
              </Form.Item>
            </Form>
          </motion.div>
          <motion.div {...animationProps} className="contact-info mt-8">
            <Row gutter={[16, 16]} className="text-center">
              <Col span={8} className="flex flex-col items-center">
                <PhoneOutlined className="text-4xl text-8b4513 mb-2" />
                <p className="text-8b4513">0962-285-6811</p>
              </Col>
              <Col span={8} className="flex flex-col items-center">
                <MailOutlined className="text-4xl text-8b4513 mb-2" />
                <p className="text-8b4513 break-all">kalapayshop@gmail.com</p>
              </Col>
              <Col span={8} className="flex flex-col items-center">
                <EnvironmentOutlined className="text-4xl text-8b4513 mb-2" />
                <p className="text-8b4513">Basco, Batanes</p>
              </Col>
            </Row>
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default ContactPage;
