import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const OrdersContext = createContext();

export const OrdersProvider = ({ children }) => {
  const [orders, setOrders] = useState([]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const response = await axios.get(
          "http://kalapay.shop/KalapayAPIupload/getOrders.php"
        );
        if (Array.isArray(response.data)) {
          setOrders(response.data);
        } else {
          setOrders([]);
          console.error("Unexpected response data format:", response.data);
        }
      } catch (error) {
        console.error("Error fetching orders:", error);
        setOrders([]);
      }
    };

    fetchOrders();
  }, []);

  return (
    <OrdersContext.Provider value={{ orders }}>
      {children}
    </OrdersContext.Provider>
  );
};
