import React, { useEffect, useState } from "react";
import { Row, Col, Typography } from "antd";
import { motion } from "framer-motion";
import axios from "axios";
import "./About.css";
import bannerImage1 from "../img/about-img.png";

const { Title } = Typography;

const About = () => {
  const [data, setData] = useState({ title: "", content: "" });

  useEffect(() => {
    const handleClick = () => {
      window.scrollTo({ top: 0 });
    };

    document.addEventListener("click", handleClick);

    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, []);

  useEffect(() => {
    axios
      .get("http://kalapay.shop/KalapayAPIupload/about.php")
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.error("There was an error fetching the About Page!", error);
      });
  }, []);

  const animationProps = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 },
  };

  return (
    <div className="about-page">
      <Row justify="center" align="middle" className="content">
        <Col xs={22} sm={20} md={18} lg={14}>
          <motion.div {...animationProps}>
            <Title level={1} className="about-title">
              {data.title}
            </Title>
          </motion.div>
          <motion.div {...animationProps}></motion.div>
          <motion.div {...animationProps}>
            <img
              src={bannerImage1}
              alt="Ivatan Products"
              className="about-image"
            />
          </motion.div>
          <motion.div {...animationProps}>
            <div
              className="about-paragraph"
              dangerouslySetInnerHTML={{ __html: data.content }}
            />
          </motion.div>
        </Col>
      </Row>
    </div>
  );
};

export default About;
