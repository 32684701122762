import React, { useContext, useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { IoMdClose } from "react-icons/io";
import CartItem from "../components/CartItem";
import { SidebarContext } from "../contexts/SidebarContext";
import { CartContext } from "../contexts/CartContext";
import { UserContext } from "../contexts/UserContext";
import { Modal, Button, Typography, Empty, Divider } from "antd";
import { motion } from "framer-motion";
import { formatPrice } from "../components/formatPrice";
import "../components/Sidebar.css";

const { Title, Text } = Typography;

const Sidebar = () => {
  const { isOpen, handleClose } = useContext(SidebarContext);
  const { cart, clearCart, total, itemAmount } = useContext(CartContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const sidebarRef = useRef(null);
  const [showNote, setShowNote] = useState(true);
  const handleDeleteClick = () => {
    setDeleteModalVisible(true);
  };

  const handleConfirmDelete = () => {
    clearCart();
    setDeleteModalVisible(false);
  };

  const handleCancelDelete = () => {
    setDeleteModalVisible(false);
  };

  const handleCheckoutClick = () => {
    handleClose();
    if (user) {
      navigate("/checkout");
    } else {
      navigate("/login");
    }
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      handleClose();
    }
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "hidden";
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className="fixed inset-0 bg-black bg-opacity-50 z-10"
          onClick={handleClose}
        />
      )}
      <div
        ref={sidebarRef}
        className={`${
          isOpen ? "right-0" : "-right-full"
        } fixed top-0 h-full w-[94vw] sm:w-2/3 md:w-1/2 lg:w-[35vw] xl:max-w-[30vw] bg-white shadow-2xl transition-all duration-500 z-20 flex flex-col`}
      >
        {isOpen && (
          <>
            <motion.div
              key={isOpen}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 1 }}
              className="p-4 border-b flex items-center justify-between"
              style={{
                position: "sticky",
                top: 6,
                backgroundColor: "white",
                zIndex: 10,
              }}
            >
              <Title level={5} className="uppercase">
                Kalapay ({itemAmount})
              </Title>
              <Button
                type="text"
                icon={<IoMdClose size={24} />}
                onClick={handleClose}
              />
            </motion.div>

            <motion.div
              key={`${isOpen}-content`}
              initial={{ opacity: 0, y: 50 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 50 }}
              transition={{ duration: 1 }}
              className="p-4 flex-1 overflow-y-auto"
            >
              {cart.length === 0 ? (
                <div className="flex-1 flex items-center justify-center">
                  <Empty description="No items in Kalapay" />
                </div>
              ) : (
                <div className="flex flex-col items-center">
                  {cart.map((item, index) => (
                    <motion.div
                      key={item.id}
                      initial={{ opacity: 0, y: 50 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ duration: 0.5, delay: index * 0.1 }}
                      className="w-full"
                    >
                      <CartItem item={item} />
                    </motion.div>
                  ))}
                </div>
              )}
            </motion.div>
          </>
        )}

        {isOpen && (
          <motion.div
            key={`footer-${isOpen}`}
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 50 }}
            transition={{ duration: 1 }}
            className="p-2"
          >
            <Divider className="my-2" />
            <div className="py-2" style={{ border: "1px solid transparent" }}>
              <div className="flex justify-between items-center mb-2">
                <Text className="uppercase font-semibold">
                  <span className="mr-2">Total:</span> {formatPrice(total)}
                </Text>
                <Button
                  type="text"
                  onClick={handleDeleteClick}
                  danger
                  className="clear-cart-button"
                >
                  Clear Kalapay
                </Button>
              </div>
              <div className="flex flex-col mb-7">
                {showNote && (
                  <div className="bg-yellow-200 p-2 rounded-md mb-2 relative">
                    <Button
                      type="text"
                      onClick={() => setShowNote(false)}
                      className="text-black absolute top-1 right-1"
                      style={{ padding: 0, height: "auto", width: "auto" }}
                    >
                      <IoMdClose size={15} />
                    </Button>
                    <Text>
                      <strong>Note:</strong> Shipping is currently available
                      only within the Batanes area. We are working to expand our
                      delivery services soon.
                    </Text>
                  </div>
                )}
                <Link to="/" onClick={handleClose}>
                  <Button
                    type="default"
                    block
                    className="custom-hover mb-2 h-9"
                  >
                    Continue Shopping
                  </Button>
                </Link>
                <Button
                  type="primary"
                  block
                  onClick={handleCheckoutClick}
                  className="bg-black text-white border-black custom-hover h-9"
                >
                  Checkout
                </Button>
              </div>
            </div>
          </motion.div>
        )}

        <Modal
          title="Are you sure you want to delete all items?"
          visible={deleteModalVisible}
          onOk={handleConfirmDelete}
          onCancel={handleCancelDelete}
          okText="Yes"
          cancelText="No"
        >
          <p>This action cannot be undone.</p>
        </Modal>
      </div>
    </>
  );
};

export default Sidebar;
