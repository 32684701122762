import React, { useContext, useState, useEffect, lazy, Suspense } from "react";
import { ProductContext } from "../contexts/ProductContext";
import axios from "axios";
import { Statistic, Card, Row, Col, Popconfirm } from "antd";
import {
  UserOutlined,
  UserAddOutlined,
  ShoppingCartOutlined,
  ShopOutlined,
  FolderOutlined,
  FolderOpenOutlined,
  OrderedListOutlined,
  WarningOutlined,
} from "@ant-design/icons";
import {
  message,
  Menu,
  Layout,
  Form,
  Input,
  Button,
  Select,
  Table,
  Upload,
  Space,
  Typography,
  Modal,
  List,
  Avatar,
} from "antd";
import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  UploadOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import "../pages/Admin.css";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { Link } from "react-router-dom";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import {
  ShoppingBagOutlined,
  HomeOutlined,
  LogoutOutlined,
} from "@ant-design/icons";
import { UserContext } from "../contexts/UserContext";
import { formatPrice } from "../components/formatPrice";
import { UpOutlined, DownOutlined } from "@ant-design/icons";
import HeaderWithNotification from "../components/HeaderWithNotification";
import { useNavigate, useLocation } from "react-router-dom";
import { useAuth } from "../AuthContext";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Brand from "../img/kalapayLogo2.png";
import MessageIcon from "@mui/icons-material/Message";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import AboutEdit from "./AboutEdit";
import BannerManagement from "../components/BannerManagement";
const { Sider, Content } = Layout;
const { Title } = Typography;
const { Option } = Select;
const { confirm } = Modal;

const { Column } = Table;
const Admin = () => {
  const { products, fetchProducts } = useContext(ProductContext);
  const [newProduct, setNewProduct] = useState({
    title: "",
    price: "",
    category: "",
    subcategory: "",
    description: "",
    image: null,
    stock: "",
    product_img_one: null,
    product_img_two: null,
    product_img_three: null,
    sizes: "",
  });
  const handleQuillChange = (value) => {
    handleInputChange(value, "description");
  };
  const [editingProduct, setEditingProduct] = useState(null);
  const [categories, setCategories] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [newCategory, setNewCategory] = useState("");
  const [newSubcategory, setNewSubcategory] = useState("");
  const [currentSection, setCurrentSection] = useState("dashboard");
  const [isDrawerVisible, setIsDrawerVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [deleteRecord, setDeleteRecord] = useState(null);
  const [deleteType, setDeleteType] = useState("");
  const { user, loading: userLoading } = useContext(UserContext);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedOrderId, setSelectedOrderId] = useState(null);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState("");
  const [usernameFilter, setUsernameFilter] = useState("");
  const location = useLocation();
  const { isAuthenticated, setIsAuthenticated } = useAuth();
  const isAdminRoute = location.pathname.startsWith("/admin");
  const navigate = useNavigate();
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalAdmins, setTotalAdmins] = useState(0);
  const [totalSales, setTotalSales] = useState(0);
  const [totalProducts, setTotalProducts] = useState(0);
  const [totalCategories, setTotalCategories] = useState(0);
  const [totalSubcategories, setTotalSubcategories] = useState(0);
  const [totalOrders, setTotalOrders] = useState(0);
  const [statusFilter, setStatusFilter] = useState("all");
  const [searchTerm, setSearchTerm] = useState("");
  const [activeButton, setActiveButton] = useState("all");
  const [lowStockProducts, setLowStockProducts] = useState([]);
  const [canceledOrders, setCanceledOrders] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [selectedSubcategory, setSelectedSubcategory] = useState(null);
  const [users, setUsers] = useState([]);
  const handleAllOrders = () => {
    setActiveButton("all");
    setStatusFilter("non-canceled");
  };
  const handleCompletedOrders = () => {
    setActiveButton("completed");
    setStatusFilter("completed");
  };
  const handlePendingOrders = () => {
    setActiveButton("pending");
    setStatusFilter("pending");
  };
  const handleProcessingOrders = () => {
    setActiveButton("processing");
    setStatusFilter("processing");
  };
  const handleCanceledOrders = () => {
    setActiveButton("canceled");
    setStatusFilter("canceled");
  };

  const handleBackToDashboard = () => {
    setCurrentSection("dashboard");
  };

  const filterLowStockProducts = () => {
    const lowStockProducts = products.filter((product) => product.stock < 8);
    setLowStockProducts(lowStockProducts);
  };

  const handleCancelOrder = async (orderId) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/updateOrderStatus.php",
        { id: orderId, status: "canceled" },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setOrders((prevOrders) => {
        const orderToCancel = prevOrders.find((order) => order.id === orderId);
        setCanceledOrders((prevCanceledOrders) => [
          ...prevCanceledOrders,
          { ...orderToCancel, status: "canceled" },
        ]);
        return prevOrders.filter((order) => order.id !== orderId);
      });
      message.success("Order canceled successfully");
    } catch (error) {
      message.error("Failed to cancel order");
    }
  };
  const [messages, setMessages] = useState([]);

  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const fetchUsers = async () => {
    try {
      const response = await fetch(
        "http://kalapay.shop/KalapayAPIupload/getUsers.php",
        {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error("Error fetching users:", error);
    }
  };
  const deleteUser = async (userId) => {
    try {
      const response = await fetch(
        "http://kalapay.shop/KalapayAPIupload/deleteUser.php",
        {
          method: "DELETE",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ id: userId }),
        }
      );
      const result = await response.json();
      if (result.message) {
        setUsers(users.filter((user) => user.id !== userId));
        message.success("User deleted successfully");
      } else {
        console.error("Error deleting user:", result.error);
        message.error("Error deleting user: " + result.error);
      }
    } catch (error) {
      console.error("Error deleting user:", error);
      message.error("Error deleting user: " + error.message);
    }
  };
  const handleTotalCustomersClick = () => {
    fetchUsers();
    setCurrentSection("users");
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchMessages();
      setMessages(data);
    };

    fetchData();
  }, []);

  const getFilteredOrders = () => {
    let filteredOrders = orders;

    switch (statusFilter) {
      case "completed":
        filteredOrders = filteredOrders.filter(
          (order) => order.status === "completed"
        );
        break;
      case "pending":
        filteredOrders = filteredOrders.filter(
          (order) => order.status === "pending"
        );
        break;
      case "processing":
        filteredOrders = filteredOrders.filter(
          (order) => order.status === "processing"
        );
        break;
      case "canceled":
        filteredOrders = filteredOrders.filter(
          (order) => order.status === "canceled" || order.status === "removed"
        );
        break;
      case "non-canceled":
        filteredOrders = filteredOrders.filter(
          (order) => order.status !== "canceled" && order.status !== "removed"
        );
        break;
      default:
        filteredOrders = filteredOrders.filter(
          (order) => order.status !== "canceled" && order.status !== "removed"
        );
        break;
    }

    if (searchTerm) {
      filteredOrders = filteredOrders.filter((order) => {
        const {
          username,
          id,
          address,
          zip_code,
          notes,
          preferred_delivery_time,
          preferred_pickup_time,
        } = order;
        const searchFields = [
          username ? username.toLowerCase() : "",
          id ? id.toString() : "",
          address ? address.toLowerCase() : "",
          zip_code ? zip_code.toString() : "",
          notes ? notes.toLowerCase() : "",
          preferred_delivery_time ? preferred_delivery_time.toLowerCase() : "",
          preferred_pickup_time ? preferred_pickup_time.toLowerCase() : "",
        ];

        if (usernameFilter) {
          return (
            (username || "").includes(usernameFilter) &&
            searchFields.some((field) =>
              field.includes(searchTerm.toLowerCase())
            )
          );
        } else {
          return searchFields.some((field) =>
            field.includes(searchTerm.toLowerCase())
          );
        }
      });
    }

    return filteredOrders;
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    navigate("/admin-login");
  };

  function getFileNameFromUrl(url) {
    if (typeof url !== "string" || !url) {
      return "";
    }

    const segments = url.split("/");
    const lastSegment = segments.pop() || "";
    return lastSegment.includes("?")
      ? lastSegment.slice(0, lastSegment.indexOf("?"))
      : lastSegment;
  }

  const fetchData = async () => {
    try {
      const usersResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/countUsers.php",
        { action: "countUsers" }
      );
      const { success: userSuccess, total_users } = usersResponse.data;
      if (userSuccess) {
        setTotalUsers(total_users);
      } else {
        console.error("Error counting users");
      }

      const adminsResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/countAdmins.php",
        { action: "countAdmins" }
      );
      const { success: adminSuccess, total_admins } = adminsResponse.data;
      if (adminSuccess) {
        setTotalAdmins(total_admins);
      } else {
        console.error("Error counting admins");
      }

      const totalSalesResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/totalSales.php",
        { action: "getTotalSales" }
      );
      const { success: totalSalesSuccess, total_sales } =
        totalSalesResponse.data;
      if (totalSalesSuccess) {
        setTotalSales(total_sales);
      } else {
        console.error("Error fetching total sales");
      }

      const totalProductsResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/totalProducts.php",
        { action: "getTotalProducts" }
      );
      const { success: totalProductsSuccess, total_products } =
        totalProductsResponse.data;
      if (totalProductsSuccess) {
        setTotalProducts(total_products);
      } else {
        console.error("Error fetching total products");
      }

      const totalCategoriesResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/getTotalCatSub.php",
        { action: "getTotalCategories" }
      );
      const { success: totalCategoriesSuccess, total_categories } =
        totalCategoriesResponse.data;
      if (totalCategoriesSuccess) {
        setTotalCategories(total_categories);
      } else {
        console.error("Error fetching total categories");
      }

      const totalSubcategoriesResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/getTotalCatSub.php",
        { action: "getTotalSubcategories" }
      );
      const { success: totalSubcategoriesSuccess, total_subcategories } =
        totalSubcategoriesResponse.data;
      if (totalSubcategoriesSuccess) {
        setTotalSubcategories(total_subcategories);
      } else {
        console.error("Error fetching total subcategories");
      }

      const totalOrdersResponse = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/getTotalOrders.php",
        { action: "getTotalOrders" }
      );

      const { success: totalOrdersSuccess, total_orders } =
        totalOrdersResponse.data;

      if (totalOrdersSuccess) {
        setTotalOrders(total_orders);
      } else {
        console.error("Error fetching total orders");
      }
    } catch (error) {
      console.error("Error fetching data", error);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      fetchData();
    }, 3000); // 3000 milliseconds = 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchOrders();
    fetchProducts().then(() => {
      filterLowStockProducts();
    });
  }, []);

  useEffect(() => {
    filterLowStockProducts();
  }, [products]);

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchOrders();

    const interval = setInterval(fetchOrders, 3000);

    return () => clearInterval(interval);
  }, []);

  const [newOrders, setNewOrders] = useState([]);

  useEffect(() => {
    fetchCategories();
    fetchSubcategories();
    fetchOrders();

    const interval = setInterval(fetchOrders, 3000);

    return () => clearInterval(interval);
  }, []);

  const handleUsernameFilterChange = (value) => {
    setUsernameFilter(value);
  };

  const filteredOrders = orders.filter((order) => {
    const { username, id, address, zip_code, notes, preferred_delivery_time } =
      order;
    const searchFields = [
      username ? username.toLowerCase() : "",
      id ? id.toString() : "",
      address ? address.toLowerCase() : "",
      zip_code ? zip_code.toString() : "",
      notes ? notes.toLowerCase() : "",
      preferred_delivery_time ? preferred_delivery_time.toLowerCase() : "",
    ];

    if (usernameFilter) {
      return (
        (username || "").includes(usernameFilter) &&
        searchFields.some((field) => field.includes(searchTerm.toLowerCase()))
      );
    } else {
      return searchFields.some((field) =>
        field.includes(searchTerm.toLowerCase())
      );
    }
  });

  const uniqueUsernames = Array.from(
    new Set(orders.map((order) => order.username))
  );

  const usernameFilterDropdown = (
    <Select
      placeholder="All"
      optionFilterProp="children"
      onChange={handleUsernameFilterChange}
      filterOption={(input, option) =>
        option.children.toLowerCase().includes(input.toLowerCase())
      }
      dropdownStyle={{ width: "auto" }}
      style={{ width: 130 }}
    >
      <Option value="" dropdownStyle={{ width: 200 }}>
        All
      </Option>
      {uniqueUsernames.map((username) => (
        <Option key={username} value={username}>
          {username}
        </Option>
      ))}
    </Select>
  );

  const fetchCategories = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/getCategories.php"
      );
      if (Array.isArray(response.data)) {
        const formattedCategories = response.data.map((category, index) => ({
          id: index + 1,
          name: category,
        }));
        setCategories(formattedCategories);
      } else {
        message.error("Failed to fetch categories: Unexpected response format");
      }
    } catch (error) {
      message.error("Failed to fetch categories");
    }
  };

  const fetchSubcategories = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/getSubcategories.php"
      );
      if (Array.isArray(response.data)) {
        const formattedSubcategories = response.data.map(
          (subcategory, index) => ({
            id: index + 1,
            name: subcategory,
          })
        );
        setSubcategories(formattedSubcategories);
      } else {
        message.error(
          "Failed to fetch subcategories: Unexpected response format"
        );
      }
    } catch (error) {
      message.error("Failed to fetch subcategories");
    }
  };

  const handleCategoryChange = (value) => {
    if (editingProduct) {
      setEditingProduct({ ...editingProduct, category: value });
    } else {
      setNewProduct({ ...newProduct, category: value });
    }
  };

  const handleSubcategoryChange = (value) => {
    if (editingProduct) {
      setEditingProduct({ ...editingProduct, subcategory: value });
    } else {
      setNewProduct({ ...newProduct, subcategory: value });
    }
  };

  const handleDescriptionChange = (value) => {
    if (editingProduct) {
      setEditingProduct((prevState) => ({ ...prevState, description: value }));
    } else {
      setNewProduct((prevState) => ({ ...prevState, description: value }));
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    if (editingProduct) {
      setEditingProduct((prevState) => ({ ...prevState, [name]: value }));
    } else {
      setNewProduct((prevState) => ({ ...prevState, [name]: value }));
    }
  };

  const handleFileChange = (event, field) => {
    const file = event.file.originFileObj || event.file;

    if (event.file.status === "removed") {
      if (editingProduct) {
        setEditingProduct((prevProduct) => ({ ...prevProduct, [field]: null }));
      } else {
        setNewProduct((prevProduct) => ({ ...prevProduct, [field]: null }));
      }
    } else {
      if (editingProduct) {
        setEditingProduct((prevProduct) => ({ ...prevProduct, [field]: file }));
      } else {
        setNewProduct((prevProduct) => ({ ...prevProduct, [field]: file }));
      }
    }
  };

  const getFileList = (file) => {
    if (!file) {
      return [];
    }
    return file instanceof File || file instanceof Blob
      ? [
          {
            uid: "-1",
            name: file.name,
            status: "done",
            url: URL.createObjectURL(file),
          },
        ]
      : typeof file === "string" && file.length > 0
      ? [
          {
            uid: "-1",
            name: file.split("/").pop(),
            status: "done",
            url: file,
          },
        ]
      : [];
  };

  const handleCreate = async () => {
    // Check if at least one field is filled
    const hasData = Object.values(newProduct).some(
      (value) => value !== "" && value !== null
    );

    if (!hasData) {
      message.warning(
        "Please fill in at least one field before adding the product."
      );
      return;
    }

    const requiredFields = [
      "title",
      "price",
      "category",
      "subcategory",
      "image",
      "stock",
      "description",
    ];
    const missingFields = requiredFields.filter((field) => !newProduct[field]);

    if (missingFields.length > 0) {
      message.warning(
        `Please fill in the required fields: ${missingFields.join(", ")}`
      );
      return;
    }

    const formData = new FormData();
    Object.keys(newProduct).forEach((key) => {
      if (newProduct[key] !== "" && newProduct[key] !== null) {
        formData.append(key, newProduct[key]);
      }
    });

    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/createProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setNewProduct({
        title: "",
        price: "",
        category: "",
        subcategory: "",
        description: "",
        image: null,
        stock: "",
        product_img_one: null,
        product_img_two: null,
        product_img_three: null,
        sizes: "",
        preferred_delivery_time: "",
        preferred_pickup_time: "",
      });
      await fetchProducts();
      message.success("Product added successfully");
    } catch (error) {
      message.error("Failed to add product");
    }
  };

  const handleEdit = (product) => {
    setEditingProduct({
      ...product,
      existingImage: product.image,
      product_img_one: product.product_img_one || null,
      product_img_two: product.product_img_two || null,
      product_img_three: product.product_img_three || null,
    });
    setCurrentSection("add-product");
  };
  const handleUpdate = async () => {
    const formData = new FormData();

    Object.keys(editingProduct).forEach((key) => {
      if (editingProduct[key]) {
        formData.append(key, editingProduct[key]);
      } else {
        formData.append(key, "");
      }
    });

    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/updateProduct.php",
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
      setEditingProduct(null);
      await fetchProducts();
      message.success("Product updated successfully");
      setCurrentSection("manage-products"); // Set the section back to "manage-products"
    } catch (error) {
      message.error("Failed to update product");
    }
  };

  const handleDeleteOrder = async (orderId) => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/deleteOrderAdmin.php",
        { id: orderId },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchOrders();
      message.success("Order deleted successfully");
    } catch (error) {
      message.error("Failed to delete order");
    }
  };

  const handleDelete = (id) => {
    setIsModalVisible(true);
    setDeleteRecord(id);
    setDeleteType("product");
  };

  const confirmDelete = async () => {
    try {
      await axios.delete(
        `http://kalapay.shop/KalapayAPIupload/deleteProduct.php?id=${deleteRecord}`
      );
      await fetchProducts();
      message.success("Product deleted successfully");
    } catch (error) {
      message.error("Failed to delete product");
    } finally {
      setIsModalVisible(false);
      setDeleteRecord(null);
    }
  };
  const handleCreateCategory = async () => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/createCategory.php",
        {
          name: newCategory,
        }
      );
      setNewCategory("");
      fetchCategories();
      message.success("Category added successfully");
    } catch (error) {
      message.error("Failed to add category");
    }
  };

  const handleDeleteCategory = (category) => {
    setIsModalVisible(true);
    setDeleteRecord(category);
    setDeleteType("category");
  };

  const confirmDeleteCategory = async () => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/deleteCategory.php",
        {
          name: deleteRecord,
        }
      );
      fetchCategories();
      message.success("Category deleted successfully");
    } catch (error) {
      message.error("Failed to delete category");
    } finally {
      setIsModalVisible(false);
      setDeleteRecord(null);
    }
  };

  const handleCreateSubcategory = async () => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/createSubcategory.php",
        {
          name: newSubcategory,
        }
      );
      setNewSubcategory("");
      fetchSubcategories();
      message.success("Subcategory added successfully");
    } catch (error) {
      message.error("Failed to add subcategory");
    }
  };

  const handleDeleteSubcategory = (subcategory) => {
    setIsModalVisible(true);
    setDeleteRecord(subcategory);
    setDeleteType("subcategory");
  };

  const confirmDeleteSubcategory = async () => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/deleteSubcategory.php",
        {
          name: deleteRecord,
        }
      );
      fetchSubcategories();
      message.success("Subcategory deleted successfully");
    } catch (error) {
      message.error("Failed to delete subcategory");
    } finally {
      setIsModalVisible(false);
      setDeleteRecord(null);
    }
  };

  const handleMenuClick = (e) => {
    setCurrentSection(e.key);
    if (e.key === "add-product") {
      setEditingProduct(null);
    }
  };

  const fetchOrders = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/getOrdersAdmin.php"
      );
      setOrders(response.data);
    } catch (error) {
      console.error("Failed to fetch orders", error);
      message.error("Failed to fetch orders");
    }
  };

  const handleOrderStatusChange = async (orderId, newStatus) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/updateOrderStatus.php",
        { id: orderId, status: newStatus },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      fetchOrders();
      message.success("Order status updated successfully");
    } catch (error) {
      message.error("Failed to update order status");
    }
  };

  const fetchMessages = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        `http://kalapay.shop/KalapayAPIupload/getMessages.php?page=${page}&limit=10`
      );
      setMessages((prevMessages) => [
        ...(prevMessages || []),
        ...response.data,
      ]);
      setHasMore(response.data.length === 10);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching messages:", error);
      setLoading(false);
    }
  };

  const handleDeleteMessage = async (messageId) => {
    if (!messageId) {
      message.error("Message ID is undefined");
      return;
    }

    try {
      console.log(`Deleting message with ID: ${messageId}`);
      await axios.delete(
        `http://kalapay.shop/KalapayAPIupload/deleteMessage.php?id=${messageId}`
      );

      setMessages((prevMessages) =>
        prevMessages.filter((message) => message.id !== messageId)
      );

      message.success("Message deleted successfully");
    } catch (error) {
      console.error(`Failed to delete message: ${error.message}`);
      message.error("Failed to delete message");
    }
  };

  const showDeleteConfirm = (orderId) => {
    confirm({
      title: "Are you sure you want to cancel this order?",
      content: "This action cannot be undone.",
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        handleCancelOrder(orderId);
      },
      onCancel() {
        console.log("Cancel");
      },
    });
  };

  useEffect(() => {
    fetchMessages();
  }, [page]);

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight = document.documentElement.scrollHeight;
      const scrollTop = document.documentElement.scrollTop;
      const clientHeight = document.documentElement.clientHeight;

      if (scrollTop + clientHeight >= scrollHeight && hasMore && !loading) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [hasMore, loading]);

  const orderColumns = [
    {
      title: "Order ID",
      dataIndex: "id",
      key: "id",
      sorter: (a, b) => a.id - b.id,
      defaultSortOrder: "descend",
    },
    {
      title: "Customer",
      dataIndex: "username",
      key: "username",
      filterDropdown: usernameFilterDropdown,
      onFilter: (value, record) => record.username === value,
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Zip Code",
      dataIndex: "zip_code",
      key: "zip_code",
    },
    {
      title: "Instruction",
      dataIndex: "notes",
      key: "notes",
    },
    {
      title: "Total",
      dataIndex: "total",
      key: "total",
      sorter: (a, b) => a.total - b.total,
    },
    {
      title: "Delivery Option",
      dataIndex: "delivery_option",
      key: "delivery_option",
    },
    {
      title: "Delivery Time",
      dataIndex: "preferred_delivery_time",
      key: "preferred_delivery_time",
    },
    {
      title: "Pickup Time",
      dataIndex: "preferred_pickup_time",
      key: "preferred_pickup_time",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      sorter: (a, b) => a.status.localeCompare(b.status),
      render: (status, record) => (
        <div style={{ padding: "8px" }}>
          {status === "pending" ? (
            <>
              <Button
                type="primary"
                onClick={() => handleOrderStatusChange(record.id, "processing")}
              >
                Confirm
              </Button>
              <Button
                type="danger"
                onClick={() => showDeleteConfirm(record.id)}
              >
                Cancel
              </Button>
            </>
          ) : status !== "canceled" && status !== "removed" ? (
            <Select
              defaultValue={status}
              onChange={(value) => handleOrderStatusChange(record.id, value)}
            >
              <Option value="completed">Completed</Option>
              <Option value="ready_to_pickup">Ready to Pickup</Option>
              <Option value="order_in_transit">Order in Transit</Option>
            </Select>
          ) : (
            <>
              <div style={{ display: "flex", alignItems: "center" }}>
                <span>Canceled</span>
                <Button
                  icon={<DeleteOutlined />}
                  type="danger"
                  onClick={() => handleDeleteOrder(record.id)}
                  style={{
                    marginLeft: 8,
                    backgroundColor: "pink",
                    color: "#EE4E4E",
                  }}
                ></Button>
              </div>
            </>
          )}
        </div>
      ),
    },
  ];

  const expandedRowRender = (record) => (
    <List
      itemLayout="horizontal"
      dataSource={record.order_items}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Avatar src={item.image} />}
            title={item.title}
            description={`Quantity: ${item.quantity} - ${formatPrice(
              item.price
            )} each`}
          />
          <div>{formatPrice(item.price * item.quantity)}</div>
        </List.Item>
      )}
    />
  );

  const showModal = (orderId) => {
    setSelectedOrderId(orderId);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
  };

  const handleCategoryFilter = (value) => {
    setSelectedCategory(value);
  };

  const handleSubcategoryFilter = (value) => {
    setSelectedSubcategory(value);
  };
  const CustomImage = ({ src, alt, style }) => (
    <img src={src} alt={alt} style={{ maxWidth: "100%", ...style }} />
  );
  const columns = [
    {
      title: "Image",
      dataIndex: "image",
      render: (text) => (
        <CustomImage src={text} alt="product" style={{ width: "100px" }} />
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
    },
    {
      title: "Price",
      dataIndex: "price",
    },
    {
      title: "Category",
      dataIndex: "category",
      key: "category",
      sorter: (a, b) => a.category.localeCompare(b.category),
      filters: Array.from(
        new Set(products.map((product) => product.category))
      ).map((category) => ({
        text: category,
        value: category,
      })),
      onFilter: (value, record) => record.category === value,
      filterDropdown: (
        <Select
          value={selectedCategory}
          onChange={(value) => setSelectedCategory(value)}
          style={{ width: 200 }}
          allowClear
        >
          <Select.Option value={null}>All Categories</Select.Option>
          {Array.from(new Set(products.map((product) => product.category))).map(
            (category) => (
              <Select.Option key={category} value={category}>
                {category}
              </Select.Option>
            )
          )}
        </Select>
      ),
    },
    {
      title: "Subcategory",
      dataIndex: "subcategory",
      key: "subcategory",
      sorter: (a, b) => a.subcategory.localeCompare(b.subcategory),
      filters: Array.from(
        new Set(products.map((product) => product.subcategory))
      ).map((subcategory) => ({
        text: subcategory,
        value: subcategory,
      })),
      onFilter: (value, record) => record.subcategory === value,
      filterDropdown: (
        <Select
          value={selectedSubcategory}
          onChange={(value) => setSelectedSubcategory(value)}
          style={{ width: 200 }}
          allowClear
        >
          <Select.Option value={null}>All Subcategories</Select.Option>
          {Array.from(
            new Set(products.map((product) => product.subcategory))
          ).map((subcategory) => (
            <Select.Option key={subcategory} value={subcategory}>
              {subcategory}
            </Select.Option>
          ))}
        </Select>
      ),
    },
    {
      title: "Stock",
      dataIndex: "stock",
    },
    {
      title: "Actions",
      render: (record) => (
        <Space size="middle">
          <Button
            type="primary"
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          />
          <Button
            type="danger"
            icon={<DeleteOutlined />}
            onClick={() => handleDelete(record.id)}
            style={{
              backgroundColor: "pink",
              borderColor: "pink",
              color: "#EE4E4E",
            }}
          />
        </Space>
      ),
    },
  ];

  return (
    <Layout className="min-h-screen bg-white flex flex-col md:flex-row">
      <Sider
        collapsible
        collapsedWidth="0"
        breakpoint="md"
        onBreakpoint={(broken) => setIsDrawerVisible(broken)}
        className="bg-white fixed-sider"
      >
        <div className="sider-header">
          <Link
            to="/admin"
            style={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          >
            <img src={Brand} alt="Kalapay" className="logo" />
            <span className="brand-text">Kalapay</span>
          </Link>
        </div>
        <Menu
          mode="inline"
          selectedKeys={[currentSection]}
          onClick={handleMenuClick}
        >
          <Menu.Item key="dashboard" icon={<DashboardIcon />}>
            Dashboard
          </Menu.Item>
          <Menu.Item key="add-product" icon={<PlusOutlined />}>
            Add Product
          </Menu.Item>
          <Menu.Item key="add-category" icon={<PlusOutlined />}>
            Add Category
          </Menu.Item>
          <Menu.Item key="add-subcategory" icon={<PlusOutlined />}>
            Add Subcategory
          </Menu.Item>
          <Menu.Item key="manage-products" icon={<ShoppingBagOutlinedIcon />}>
            Manage Products
          </Menu.Item>
          <Menu.Item key="order-management" icon={<ShoppingBagOutlinedIcon />}>
            Order Management
          </Menu.Item>
          <Menu.Item key="about" icon={<EditOutlined />}>
            About
          </Menu.Item>

          <Menu.Item key="banner-management" icon={<EditOutlined />}>
            Banner Management
          </Menu.Item>
          <Menu.Item key="messages" icon={<MessageIcon />}>
            Messages
          </Menu.Item>
          {isAdminRoute ? (
            <Menu.Item
              key="logout"
              icon={<LogoutOutlined />}
              onClick={handleLogout}
            >
              Logout
            </Menu.Item>
          ) : (
            <Menu.Item key="back-to-home" icon={<HomeOutlined />}>
              <Link to="/">Back to Home</Link>
            </Menu.Item>
          )}
        </Menu>
      </Sider>

      <Content className="p-6 flex-1 layout-content">
        <div className="flex justify-between items-center mb-4">
          <Title level={2} className="mb-4">
            Admin Panel
          </Title>
          <HeaderWithNotification newOrders={newOrders} />
        </div>

        {currentSection === "dashboard" && (
          <div className="site-statistic-demo-card">
            <Row gutter={16}>
              <Col span={6}>
                <Card
                  onClick={handleTotalCustomersClick}
                  style={{ cursor: "pointer" }}
                >
                  <Statistic
                    title="Total Customers"
                    value={totalUsers}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<UserOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Admins"
                    value={totalAdmins}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<UserAddOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Sales"
                    value={formatPrice(totalSales)}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<ShoppingCartOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Products"
                    value={totalProducts}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<ShopOutlined />}
                  />
                </Card>
              </Col>
            </Row>
            <Row gutter={16} style={{ marginTop: 16 }}>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Categories"
                    value={totalCategories}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<FolderOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Subcategories"
                    value={totalSubcategories}
                    valueStyle={{ color: "#cf1322" }}
                    prefix={<FolderOpenOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Total Orders"
                    value={totalOrders}
                    valueStyle={{ color: "#3f8600" }}
                    prefix={<OrderedListOutlined />}
                  />
                </Card>
              </Col>
              <Col span={6}>
                <Card>
                  <Statistic
                    title="Low Stock Products"
                    value={lowStockProducts.length}
                    valueStyle={{
                      color:
                        lowStockProducts.length > 0 ? "#cf1322" : "#3f8600",
                    }}
                    prefix={<WarningOutlined />}
                    suffix={
                      lowStockProducts.length > 0 ? (
                        <a
                          onClick={() =>
                            setCurrentSection("low-stock-products")
                          }
                          className="hover-link"
                        >
                          Low Stock Products
                        </a>
                      ) : null
                    }
                  />
                </Card>
              </Col>
            </Row>
          </div>
        )}

        {currentSection === "users" && (
          <div>
            <Table dataSource={users} rowKey="id">
              <Column title="Username" dataIndex="username" key="username" />
              <Column title="Email" dataIndex="email" key="email" />
              <Column
                title="Action"
                key="action"
                render={(text, record) => (
                  <Popconfirm
                    title="Are you sure you want to delete this user?"
                    onConfirm={() => deleteUser(record.id)}
                    okText="Yes"
                    cancelText="No"
                  >
                    <Button type="danger">Delete</Button>
                  </Popconfirm>
                )}
              />
            </Table>
            <Button
              type="primary"
              onClick={() => setCurrentSection("dashboard")}
            >
              Back to Dashboard
            </Button>
          </div>
        )}
        {currentSection === "low-stock-products" && (
          <div>
            <Button
              onClick={handleBackToDashboard}
              style={{ marginBottom: 10 }}
            >
              Back
            </Button>
            <Title level={2} style={{ color: "#B4B4B8" }}>
              Low Stock Products
            </Title>
            <Table
              dataSource={lowStockProducts}
              columns={columns}
              rowKey="id"
            />
          </div>
        )}

        {currentSection === "add-product" && (
          <div className="flex justify-center mt-4">
            <Form
              layout="vertical"
              className="w-full p-6"
              style={{
                backgroundColor: "#f7f7f7",
                borderRadius: "8px",
                boxShadow: "0 2px 8px rgba(0, 0, 0, 0.1)",
              }}
            >
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                <Form.Item label="Product Name" required>
                  <Input
                    name="title"
                    value={
                      editingProduct ? editingProduct.title : newProduct.title
                    }
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Price" required>
                  <Input
                    name="price"
                    type="number"
                    value={
                      editingProduct ? editingProduct.price : newProduct.price
                    }
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Category" required>
                  <Select
                    value={
                      editingProduct
                        ? editingProduct.category
                        : newProduct.category
                    }
                    onChange={handleCategoryChange}
                  >
                    {categories.map((category) => (
                      <Select.Option key={category.id} value={category.name}>
                        {category.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Subcategory" required>
                  <Select
                    value={
                      editingProduct
                        ? editingProduct.subcategory
                        : newProduct.subcategory
                    }
                    onChange={handleSubcategoryChange}
                  >
                    {subcategories.map((subcategory) => (
                      <Select.Option
                        key={subcategory.id}
                        value={subcategory.name}
                      >
                        {subcategory.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item label="Size (Optional)">
                  <Input
                    name="sizes"
                    value={
                      editingProduct
                        ? editingProduct.sizes || ""
                        : newProduct.sizes || ""
                    }
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Description" required className="col-span-2">
                  <ReactQuill
                    theme="snow"
                    value={
                      editingProduct
                        ? editingProduct.description
                        : newProduct.description
                    }
                    onChange={handleDescriptionChange}
                  />
                </Form.Item>

                <Form.Item label="Main Image" required>
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(e) => handleFileChange(e, "image")}
                    fileList={getFileList(
                      editingProduct ? editingProduct.image : newProduct.image
                    )}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Stock Quantity" required>
                  <Input
                    name="stock"
                    type="number"
                    value={
                      editingProduct ? editingProduct.stock : newProduct.stock
                    }
                    onChange={handleInputChange}
                  />
                </Form.Item>
                <Form.Item label="Product Image One">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(e) => handleFileChange(e, "product_img_one")}
                    fileList={getFileList(
                      editingProduct
                        ? editingProduct.product_img_one
                        : newProduct.product_img_one
                    )}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image One</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Product Image Two">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(e) => handleFileChange(e, "product_img_two")}
                    fileList={getFileList(
                      editingProduct
                        ? editingProduct.product_img_two
                        : newProduct.product_img_two
                    )}
                  >
                    <Button icon={<UploadOutlined />}>Upload Image Two</Button>
                  </Upload>
                </Form.Item>
                <Form.Item label="Product Image Three">
                  <Upload
                    listType="picture"
                    beforeUpload={() => false}
                    onChange={(e) => handleFileChange(e, "product_img_three")}
                    fileList={getFileList(
                      editingProduct
                        ? editingProduct.product_img_three
                        : newProduct.product_img_three
                    )}
                  >
                    <Button icon={<UploadOutlined />}>
                      Upload Image Three
                    </Button>
                  </Upload>
                </Form.Item>
              </div>
              <Form.Item className="mt-4">
                <Button
                  type="primary"
                  onClick={editingProduct ? handleUpdate : handleCreate}
                  style={{ marginRight: "10px" }}
                >
                  {editingProduct ? "Update Product" : "Add Product"}
                </Button>
                {editingProduct && (
                  <Button onClick={() => setCurrentSection("manage-products")}>
                    Cancel
                  </Button>
                )}
              </Form.Item>
            </Form>
          </div>
        )}

        {currentSection === "manage-products" && (
          <div className="manage-products-container">
            <Table
              columns={columns}
              dataSource={products.filter(
                (product) =>
                  (selectedCategory === null ||
                    product.category === selectedCategory) &&
                  (selectedSubcategory === null ||
                    product.subcategory === selectedSubcategory)
              )}
              rowKey="id"
              scroll={products.length > 5 ? { y: 480 } : {}}
              className="products-table"
              pagination={{ pageSize: 10 }}
            />
          </div>
        )}
        {currentSection === "order-management" && (
          <div>
            <div style={{ marginBottom: 16, marginTop: 20 }}>
              <Button
                onClick={handleAllOrders}
                className={`button ${
                  activeButton === "all" ? "button-active" : ""
                }`}
              >
                All Orders
              </Button>

              <Button
                onClick={handlePendingOrders}
                className={`button button-pending ${
                  activeButton === "pending"
                    ? "button-pending-active button-active"
                    : ""
                }`}
              >
                Pending
              </Button>
              <Button
                onClick={handleProcessingOrders}
                className={`button button-processing ${
                  activeButton === "processing"
                    ? "button-processing-active"
                    : ""
                }`}
              >
                Processing
              </Button>
              <Button
                onClick={handleCompletedOrders}
                className={`button button-completed ${
                  activeButton === "completed"
                    ? "button-completed-active button-active"
                    : ""
                }`}
              >
                Completed
              </Button>
              <Button
                onClick={handleCanceledOrders}
                className={`button button-canceled ${
                  activeButton === "canceled"
                    ? "button-canceled-active button-active"
                    : ""
                }`}
              >
                Canceled
              </Button>
            </div>

            <Input.Search
              placeholder="Search orders..."
              onChange={(e) => setSearchTerm(e.target.value)}
              style={{ marginBottom: 16 }}
            />
            <Table
              dataSource={getFilteredOrders()}
              columns={orderColumns}
              rowKey="id"
              expandable={{
                expandedRowRender: (record) => (
                  <List
                    itemLayout="horizontal"
                    dataSource={record.order_items}
                    renderItem={(item) => (
                      <List.Item>
                        <List.Item.Meta
                          avatar={<Avatar src={item.image} />}
                          title={item.title}
                          description={
                            item.selectedSize
                              ? `Quantity: ${item.quantity} - Size: ${
                                  item.selectedSize
                                } - ${formatPrice(item.price)} each`
                              : `Quantity: ${item.quantity} - ${formatPrice(
                                  item.price
                                )} each`
                          }
                        />
                        <div>{formatPrice(item.price * item.quantity)}</div>
                      </List.Item>
                    )}
                  />
                ),
                expandIcon: ({ expanded, onExpand, record }) => (
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <span
                      style={{
                        marginRight: "8px",
                        fontSize: "0.8rem",
                        fontFamily: "Raleway",
                      }}
                    >
                      View Items
                    </span>
                    <a
                      style={{ marginLeft: "auto" }}
                      onClick={(e) => onExpand(record, e)}
                    >
                      {expanded ? <UpOutlined /> : <DownOutlined />}
                    </a>
                  </div>
                ),
              }}
            />
          </div>
        )}

        {currentSection === "add-category" && (
          <Form
            layout="vertical"
            className="w-full max-w-lg p-4"
            style={{
              backgroundColor: "#efefef",
              margin: "0 auto",
              marginTop: "20px",
            }}
          >
            <Form.Item label="Category Name">
              <Input
                value={newCategory}
                onChange={(e) => setNewCategory(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleCreateCategory}>
                Add Category
              </Button>
            </Form.Item>
            <Table
              dataSource={categories}
              columns={[
                { title: "Category Name", dataIndex: "name", key: "name" },
                {
                  title: "Actions",
                  key: "actions",
                  render: (text, record) => (
                    <Button
                      icon={<DeleteOutlined />}
                      style={{
                        backgroundColor: "pink",
                        borderColor: "pink",
                        color: "#EE4E4E",
                      }}
                      onClick={() => handleDeleteCategory(record.name)}
                    />
                  ),
                },
              ]}
              rowKey="order_id"
            />
          </Form>
        )}
        {currentSection === "add-subcategory" && (
          <Form
            layout="vertical"
            className="w-full max-w-lg p-4"
            style={{
              backgroundColor: "#efefef",
              margin: "0 auto",
              marginTop: "20px",
            }}
          >
            <Form.Item label="Subcategory Name">
              <Input
                value={newSubcategory}
                onChange={(e) => setNewSubcategory(e.target.value)}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" onClick={handleCreateSubcategory}>
                Add Subcategory
              </Button>
            </Form.Item>
            <Table
              dataSource={subcategories}
              columns={[
                { title: "Subcategory Name", dataIndex: "name", key: "name" },
                {
                  title: "Actions",
                  key: "actions",
                  render: (text, record) => (
                    <Button
                      icon={<DeleteOutlined />}
                      style={{
                        backgroundColor: "pink",
                        borderColor: "pink",
                        color: "#EE4E4E",
                      }}
                      onClick={() => handleDeleteSubcategory(record.name)}
                    />
                  ),
                },
              ]}
              rowKey="id"
            />
          </Form>
        )}
        {currentSection === "banner-management" && <BannerManagement />}
        {currentSection === "messages" && (
          <div>
            <Title level={2}>
              <MessageIcon />
              Messages
            </Title>
            <List
              itemLayout="horizontal"
              dataSource={messages}
              renderItem={(item) => (
                <List.Item
                  actions={[
                    <Button
                      type="danger"
                      icon={<DeleteOutlined />}
                      onClick={() => handleDeleteMessage(item.id)}
                      style={{
                        backgroundColor: "pink",
                        borderColor: "pink",
                        color: "#EE4E4E",
                      }}
                    >
                      Delete
                    </Button>,
                  ]}
                >
                  <List.Item.Meta
                    title={item.email}
                    description={`${item.message} (${new Date(
                      item.created_at
                    ).toLocaleString()})`}
                    extra={<span>{item.email}</span>}
                  />
                </List.Item>
              )}
            />
            {loading && <div>Loading...</div>}
          </div>
        )}

        {currentSection === "about" ? <AboutEdit /> : <div></div>}
      </Content>
      <Modal
        title="Confirm Deletion"
        visible={isModalVisible}
        onOk={() => {
          if (deleteType === "product") {
            confirmDelete();
          } else if (deleteType === "category") {
            confirmDeleteCategory();
          } else if (deleteType === "subcategory") {
            confirmDeleteSubcategory();
          }
        }}
        onCancel={() => {
          setIsModalVisible(false);
          setDeleteType("");
        }}
        okText="Delete"
        cancelText="Cancel"
      >
        <p>Are you sure you want to delete this {deleteType}?</p>
      </Modal>
    </Layout>
  );
};

export default Admin;
