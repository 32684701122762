import React, { useState, useEffect } from "react";
import axios from "axios";
import { Upload, Button, List, Image, message, Popconfirm, Spin } from "antd";
import { UploadOutlined, DeleteOutlined } from "@ant-design/icons";

const BannerManagement = () => {
  const [image, setImage] = useState(null);
  const [bannerImages, setBannerImages] = useState([]);
  const [loading, setLoading] = useState(false); // State to manage loading

  useEffect(() => {
    fetchBannerImages();
  }, []);

  const fetchBannerImages = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/fetchBannerImages.php"
      );
      setBannerImages(response.data);
    } catch (error) {
      message.error("Error fetching banner images: " + error.message);
    }
  };

  const handleImageChange = (e) => {
    setImage(e.file);
  };

  const handleSubmit = async () => {
    if (!image) {
      message.error("Please select an image to upload.");
      return;
    }

    const formData = new FormData();
    formData.append("bannerImage", image);

    setLoading(true); // Set loading to true when uploading starts
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/uploadBannerImage.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      message.success(response.data.message);
      fetchBannerImages(); // Refresh the list
    } catch (error) {
      message.error("Error uploading image: " + error.message);
    } finally {
      setLoading(false); // Set loading to false when uploading ends
    }
  };

  const handleDelete = async (imageUrl) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/deleteBannerImage.php",
        { imageUrl }
      );
      message.success(response.data.message);
      fetchBannerImages(); // Refresh the list
    } catch (error) {
      message.error("Error deleting image: " + error.message);
    }
  };

  return (
    <div style={{ paddingTop: "20px" }}>
      <Upload beforeUpload={() => false} onChange={handleImageChange}>
        <Button icon={<UploadOutlined />}>Select Image</Button>
      </Upload>
      <Button type="primary" onClick={handleSubmit} style={{ marginTop: 16 }}>
        Upload Image
      </Button>
      {loading && (
        <Spin style={{ display: "block", marginTop: 16 }} /> // Show loading spinner
      )}
      <List
        itemLayout="horizontal"
        dataSource={bannerImages}
        renderItem={(item) => (
          <List.Item
            actions={[
              <Popconfirm
                title="Are you sure to delete this banner?"
                onConfirm={() => handleDelete(item.image_url)}
                okText="Yes"
                cancelText="No"
              >
                <Button icon={<DeleteOutlined />} danger />
              </Popconfirm>,
            ]}
          >
            <List.Item.Meta
              avatar={<Image width={200} src={item.image_url} />}
              description={item.image_url}
            />
          </List.Item>
        )}
      />
    </div>
  );
};

export default BannerManagement;
