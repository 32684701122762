import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Home from "./pages/Home";
import ProductDetails from "./pages/ProductDetails";
import Admin from "./pages/Admin";
import Sidebar from "./components/Sidebar";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Login from "./components/Login";
import Signup from "./components/Signup";
import CheckoutPage from "./pages/CheckoutPage";
import OrderConfirmation from "./pages/OrderConfirmation";
import Orders from "./pages/Orders";
import Contact from "./pages/Contact";
import About from "./pages/About";
import Wishlist from "./pages/Wishlist";
import Categories from "./pages/Categories";
import AccountSettings from "./pages/AccountSettings";
import AdminRegistration from "./pages/AdminRegistration";
import AdminLogin from "./pages/AdminLogin";
import ProtectedRoute from "./components/ProtectedRoute";
import { AuthProvider, useAuth } from "./AuthContext";
import WelcomeModal from "./components/WelcomeModal";
import CategoryPage from "./pages/CategoryPage";

const App = () => {
  const location = useLocation();
  const { isAuthenticated } = useAuth();
  const [showModal, setShowModal] = useState(false);

  const isAdminRoute = location.pathname.startsWith("/admin");

  useEffect(() => {
    const lastShown = localStorage.getItem("welcomeModalLastShown");
    const now = new Date().getTime();
    const hours12 = 12 * 60 * 60 * 1000; // 12 hours in milliseconds

    if (!lastShown || now - lastShown > hours12) {
      setShowModal(true);
      localStorage.setItem("welcomeModalLastShown", now);
    }
  }, []);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="min-h-screen flex flex-col">
      {!isAdminRoute && <Header />}
      <main className="flex-grow">
        {showModal && <WelcomeModal onClose={handleCloseModal} />}
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/product/:id" element={<ProductDetails />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<Signup />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/checkout" element={<CheckoutPage />} />
          <Route path="/order-confirmation" element={<OrderConfirmation />} />
          <Route path="/orders" element={<Orders />} />
          <Route path="/wishlist" element={<Wishlist />} />
          <Route path="/categories" element={<Categories />} />
          <Route path="/category/:category" element={<CategoryPage />} />
          <Route path="/account-settings" element={<AccountSettings />} />
          <Route path="/admin-registration" element={<AdminRegistration />} />
          <Route path="/admin-login" element={<AdminLogin />} />

          <Route
            path="/admin/*"
            element={
              <ProtectedRoute isAuthenticated={isAuthenticated}>
                <Admin />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
      {!isAdminRoute && <Sidebar />}
      {!isAdminRoute && <Footer />}
      <ToastContainer />
    </div>
  );
};

const AppWrapper = () => (
  <HelmetProvider>
    <Router>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Router>
  </HelmetProvider>
);

export default AppWrapper;
