import React from "react";
import { BsChevronLeft, BsChevronRight } from "react-icons/bs";

const CustomPrevArrow = ({ onClick }) => (
  <div
    style={{
      display: "block",
      position: "absolute",
      zIndex: 2,
      left: "-30px",
      top: "45%",
      transform: "translateY(-50%)",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <BsChevronLeft size={30} color="black" />
  </div>
);

const CustomNextArrow = ({ onClick }) => (
  <div
    style={{
      display: "block",
      position: "absolute",
      zIndex: 2,
      right: "-30px",
      top: "45%",
      transform: "translateY(-50%)",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <BsChevronRight size={30} color="black" />
  </div>
);

export { CustomPrevArrow, CustomNextArrow };
