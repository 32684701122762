import React, { useContext, useEffect } from "react";
import { Form, Input, Button, Card, Typography, message } from "antd";
import axios from "axios";
import { UserContext } from "../contexts/UserContext";

const { Title } = Typography;

const AccountSettings = () => {
  const { user } = useContext(UserContext);
  const [form] = Form.useForm();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const onFinish = (values) => {
    if (user && user.id) {
      axios
        .post("http://kalapay.shop/KalapayAPIupload/updateUser.php", {
          id: user.id,
          email: values.email,
          oldPassword: values.oldPassword,
          newPassword: values.newPassword,
        })
        .then((response) => {
          message.success(response.data.message);
          form.resetFields(); // Reset the form fields upon success
        })
        .catch((error) => {
          console.error("There was an error updating the user!", error);
          message.error("Failed to update user");
        });
    } else {
      message.error("User is not logged in");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "100vh",
        backgroundColor: "#f1e5d1",
      }}
    >
      <Card
        style={{
          width: "100%",
          maxWidth: "400px",
          borderRadius: "12px",
          boxShadow: "0 4px 12px rgba(0,0,0,0.1)",
          backgroundColor: "white",
        }}
      >
        <Title level={3} style={{ textAlign: "center", color: "#333" }}>
          Change Password
        </Title>
        <Form
          form={form} // Use the form instance
          name="account_settings"
          layout="vertical"
          onFinish={onFinish}
          style={{ marginTop: "24px" }}
        >
          <Form.Item
            label="Email"
            name="email"
            rules={[
              { required: true, message: "Please input your email!" },
              { type: "email", message: "Please enter a valid email address!" },
            ]}
          >
            <Input placeholder="example@email.com" />
          </Form.Item>

          <Form.Item
            label="Old Password"
            name="oldPassword"
            rules={[
              { required: true, message: "Please input your old password!" },
            ]}
          >
            <Input.Password placeholder="••••••••" />
          </Form.Item>

          <Form.Item
            label="New Password"
            name="newPassword"
            rules={[
              { required: true, message: "Please input your new password!" },
              {
                min: 6,
                message: "Password must be at least 6 characters long!",
              },
            ]}
          >
            <Input.Password placeholder="••••••••" />
          </Form.Item>

          <Form.Item
            label="Confirm Password"
            name="confirmPassword"
            dependencies={["newPassword"]}
            rules={[
              { required: true, message: "Please confirm your new password!" },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue("newPassword") === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error("The two passwords do not match!")
                  );
                },
              }),
            ]}
          >
            <Input.Password placeholder="••••••••" />
          </Form.Item>

          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              style={{
                width: "100%",
                height: "40px",
                backgroundColor: "#6f4e37",
                borderColor: "#6f4e37",
                borderRadius: "6px",
                fontSize: "16px",
                fontWeight: "500",
                marginTop: "12px",
              }}
            >
              Save Changes
            </Button>
          </Form.Item>
        </Form>
      </Card>
    </div>
  );
};

export default AccountSettings;
