import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import ProductProvider from "./contexts/ProductContext";
import { SidebarProvider } from "./contexts/SidebarContext";
import CartProvider from "./contexts/CartContext";
import { UserProvider } from "./contexts/UserContext";
import { WishlistProvider } from "./contexts/WishlistContext";
import { OrdersProvider } from "./contexts/OrdersContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <UserProvider>
      <SidebarProvider>
        <CartProvider>
          <WishlistProvider>
            <OrdersProvider>
              <ProductProvider>
                <App />
              </ProductProvider>
            </OrdersProvider>
          </WishlistProvider>
        </CartProvider>
      </SidebarProvider>
    </UserProvider>
  </React.StrictMode>
);
