import React, { useContext, useState, useEffect } from "react";
import axios from "axios";
import { Modal, message, Spin, Select } from "antd";
import { CartContext } from "../contexts/CartContext";
import { UserContext } from "../contexts/UserContext";
import { useNavigate } from "react-router-dom";
import { FaShippingFast, FaStore } from "react-icons/fa";
import { formatPrice } from "../components/formatPrice";
import "../pages/CheckoutPage.css";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import { format } from "date-fns";

const CheckoutPage = () => {
  const { cart, total, clearCart, updateProductStocks } =
    useContext(CartContext);
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [deliveryOption, setDeliveryOption] = useState("delivery");
  const [address, setAddress] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [notes, setNotes] = useState("");
  const [addressError, setAddressError] = useState("");
  const [zipCodeError, setZipCodeError] = useState("");
  const [confirmVisible, setConfirmVisible] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deliveryTime, setDeliveryTime] = useState("");
  const [pickupTime, setPickupTime] = useState("");
  const { Option } = Select;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleOptionChange = (event) => {
    setDeliveryOption(event.target.value);
    setAddressError("");
    setZipCodeError("");
  };

  const handleAddressChange = (event) => {
    setAddress(event.target.value);
  };

  const handleZipCodeChange = (event) => {
    setZipCode(event.target.value);
  };

  const handleNotesChange = (event) => {
    setNotes(event.target.value);
  };

  const handleDeliveryTimeChange = (value) => {
    setDeliveryTime(value);
  };

  const placeOrder = async () => {
    let hasError = false;

    if (deliveryOption === "delivery") {
      if (!address) {
        setAddressError("Delivery address is required");
        hasError = true;
      } else {
        setAddressError("");
      }

      if (!zipCode) {
        setZipCodeError("Zip code is required");
        hasError = true;
      } else {
        setZipCodeError("");
      }

      if (!deliveryTime) {
        message.error("Please select a delivery time.");
        hasError = true;
      }
    }

    if (hasError) {
      return;
    }

    const orderItems = cart.map((item) => ({
      product_id: item.id,
      quantity: item.quantity,
      price: item.price,
      user_id: user.id,
      selectedSize: item.selectedSize || null, // Handle optional size
    }));

    const currentDate = format(new Date(), "yyyy-MM-dd HH:mm:ss");

    const orderData = {
      user_id: user.id,
      total: total,
      delivery_option: deliveryOption,
      order_items: orderItems,
      address: deliveryOption === "delivery" ? address : null,
      zip_code: deliveryOption === "delivery" ? zipCode : null,
      notes: notes,
      order_date: currentDate,
      preferred_delivery_time:
        deliveryOption === "delivery" ? deliveryTime : null,
      preferred_pickup_time: deliveryOption === "pickup" ? pickupTime : null, // Include pickup time
    };

    console.log("Order Data:", orderData);
    const handleOrderSuccess = () => {
      Modal.success({
        title: "Order Successful",
        content: (
          <div className="flex flex-col items-center">
            <p className="mb-4 text-center">
              Order placed successfully! Your order is currently pending admin
              approval. Once approved, the status of your order will be
              automatically marked 'Processing'. Thank you!
            </p>
            <div className="flex justify-center space-x-4">
              <button
                className="bg-[#6F4E37] hover:bg-[#6F4E37] text-white font-bold py-2 px-4 rounded"
                onClick={() => {
                  navigate("/orders");
                  Modal.destroyAll();
                }}
              >
                See Orders
              </button>
              <button
                className="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded"
                onClick={() => {
                  navigate("/");
                  Modal.destroyAll();
                }}
              >
                Continue Shopping
              </button>
            </div>
          </div>
        ),
        footer: null,
        onOk: null,
      });
      updateProductStocks(orderItems);
      clearCart();
    };

    const handleOrderFailure = (errorMessage) => {
      message.error(`Order placement failed. ${errorMessage}`);
    };
    try {
      setLoading(true);
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/PHPMailer/createOrder.php",
        orderData
      );

      if (response.data.message.includes("Order placed successfully")) {
        updateProductStocks(orderItems);
        clearCart();
        navigate("/");
        handleOrderSuccess();
      } else {
        console.log("Order placement failed:", response.data);
        message.error("Order placement failed. Please try again.");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      message.error("Error placing order. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const showConfirm = () => {
    if (cart.length === 0) {
      message.error("Your kalapay is empty");
    } else {
      setConfirmVisible(true);
    }
  };

  const handleConfirmOk = () => {
    setConfirmVisible(false);
    placeOrder();
  };

  const handleConfirmCancel = () => {
    setConfirmVisible(false);
  };

  return (
    <div
      className="checkout-page container mx-auto p-6 md:p-12"
      style={{ paddingTop: "80px" }}
    >
      <h1 className="text-3xl font-semibold mb-6">Checkout</h1>
      {cart.length === 0 ? (
        <div className="empty-cart text-center mt-20">
          <p
            className="text-2xl font-semibold mb-4"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <SentimentDissatisfiedIcon style={{ fontSize: "2rem" }} />
            Your kalapay is empty
          </p>
          <button
            className="continue-shopping-btn px-6 py-3"
            onClick={() => navigate("/")}
          >
            <span> Mangalapay</span>
          </button>
        </div>
      ) : (
        <Spin spinning={loading} tip="Your order is being processed...">
          <div className="flex flex-col-reverse md:flex-row md:space-x-8">
            {/* Left side: Input Fields and Delivery Options */}
            <div className="w-full md:w-2/3 mt-6 md:mt-0">
              <div className="space-y-4">
                <div className="bg-white p-4 rounded-lg shadow-md">
                  <h2 className="text-lg font-semibold mb-3">
                    Delivery Options
                  </h2>
                  <div className="flex space-x-4">
                    <label
                      className={`delivery-option flex-1 ${
                        deliveryOption === "delivery" ? "selected" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        value="delivery"
                        checked={deliveryOption === "delivery"}
                        onChange={handleOptionChange}
                        className="hidden"
                      />
                      <div className="icon">
                        <FaShippingFast size={20} />
                      </div>
                      <span>Delivery</span>
                    </label>
                    <label
                      className={`delivery-option flex-1 ${
                        deliveryOption === "pickup" ? "selected" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        value="pickup"
                        checked={deliveryOption === "pickup"}
                        onChange={handleOptionChange}
                        className="hidden"
                      />

                      <div className="icon">
                        <FaStore size={20} />
                      </div>
                      <span>Pickup</span>
                    </label>
                  </div>
                </div>

                {deliveryOption === "delivery" && (
                  <>
                    <div className="bg-white p-4 rounded-lg shadow-md">
                      <h3 className="text-lg font-semibold mb-3">
                        Delivery Address
                      </h3>
                      <div className="flex flex-col md:flex-row md:space-x-4">
                        <div className="flex-grow mb-3 md:mb-0">
                          <input
                            type="text"
                            placeholder="Enter your address"
                            className={`w-full p-2 border rounded-md bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition text-sm ${
                              addressError ? "border-red-500" : ""
                            }`}
                            value={address}
                            onChange={handleAddressChange}
                          />
                          {addressError && (
                            <p className="text-red-500 text-xs mt-1">
                              {addressError}
                            </p>
                          )}
                        </div>
                        <div className="md:w-1/3">
                          <input
                            type="text"
                            placeholder="Enter your zip code"
                            className={`w-full p-2 border rounded-md bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition text-sm ${
                              zipCodeError ? "border-red-500" : ""
                            }`}
                            value={zipCode}
                            onChange={handleZipCodeChange}
                          />
                          {zipCodeError && (
                            <p className="text-red-500 text-xs mt-1">
                              {zipCodeError}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="bg-white p-4 rounded-lg shadow-md">
                      <h3 className="text-lg font-semibold mb-3">
                        Preferred Delivery Time
                      </h3>
                      <Select
                        className="w-full"
                        value={deliveryTime}
                        onChange={handleDeliveryTimeChange}
                        placeholder="Select a delivery time"
                      >
                        <Option value="">Select a delivery time</Option>
                        <Option value="Morning">Morning (8 AM - 12 PM)</Option>
                        <Option value="Afternoon">
                          Afternoon (12 PM - 4 PM)
                        </Option>
                        <Option value="Evening">Evening (4 PM - 8 PM)</Option>
                      </Select>
                    </div>
                  </>
                )}

                {deliveryOption === "pickup" && (
                  <div className="bg-white p-4 rounded-lg shadow-md">
                    <h3 className="text-lg font-semibold mb-3">
                      Preferred Pickup Time
                    </h3>
                    <Select
                      className="w-full"
                      value={pickupTime}
                      onChange={(value) => setPickupTime(value)}
                      placeholder="Select a pickup time"
                    >
                      <Option value="">Select a pickup time</Option>
                      <Option value="Morning">Morning (8 AM - 12 PM)</Option>
                      <Option value="Afternoon">
                        Afternoon (12 PM - 4 PM)
                      </Option>
                      <Option value="Evening">Evening (4 PM - 8 PM)</Option>
                    </Select>
                  </div>
                )}

                <div className="bg-white p-4 rounded-lg shadow-md">
                  <h2 className="text-lg font-semibold mb-3">
                    Special Instructions
                  </h2>
                  <textarea
                    placeholder="Enter any special instructions"
                    className="w-full p-2 border rounded-md bg-gray-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition text-sm"
                    value={notes}
                    onChange={handleNotesChange}
                    rows={3}
                  ></textarea>
                </div>
              </div>
            </div>

            {/* Right side: Order Summary */}
            <div className="w-full md:w-1/3">
              <div className="bg-white p-4 rounded-lg shadow-md">
                <h2 className="text-xl font-semibold mb-3">Order Summary</h2>
                <ul className="mb-4 space-y-3">
                  {cart.map((item) => (
                    <li
                      key={item.id}
                      className="flex items-center py-2 border-b last:border-b-0"
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        className="w-12 h-12 object-cover mr-3 rounded"
                      />
                      <div className="flex-1">
                        <p className="font-medium text-sm">{item.title}</p>
                        <p className="text-xs text-gray-500">
                          {formatPrice(item.price)} x {item.quantity}
                        </p>
                        {item.selectedSize && (
                          <p className="text-xs">Size: {item.selectedSize}</p>
                        )}
                      </div>
                      <div className="text-right">
                        <p className="font-medium text-sm">
                          {formatPrice(item.price * item.quantity)}
                        </p>
                      </div>
                    </li>
                  ))}
                </ul>

                <div className="flex justify-between font-semibold text-base border-t pt-3 mb-4">
                  <span>Total:</span>
                  <span>{formatPrice(total)}</span>
                </div>

                <button
                  className="place-order-btn w-full"
                  onClick={showConfirm}
                >
                  <span>Place Order</span>
                </button>
              </div>
            </div>
          </div>

          <Modal
            title="Confirm Order"
            visible={confirmVisible}
            onOk={handleConfirmOk}
            onCancel={handleConfirmCancel}
          >
            <p>Please review and confirm your order:</p>
            <ul className="mb-4 space-y-3">
              {cart.map((item) => (
                <li
                  key={item.id}
                  className="flex items-center py-2 border-b last:border-b-0"
                >
                  <img
                    src={item.image}
                    alt={item.title}
                    className="w-12 h-12 object-cover mr-3 rounded"
                  />
                  <div className="flex-1">
                    <p className="font-medium text-sm">{item.title}</p>
                    <p className="text-xs text-gray-500">
                      {formatPrice(item.price)} x {item.quantity}
                    </p>
                    {item.selectedSize && (
                      <p className="text-xs">Size: {item.selectedSize}</p>
                    )}
                  </div>
                  <div className="text-right">
                    <p className="font-medium text-sm">
                      {formatPrice(item.price * item.quantity)}
                    </p>
                  </div>
                </li>
              ))}
            </ul>

            <div className="flex justify-between font-semibold text-base border-t pt-3 mb-4">
              <span>Total:</span>
              <span>{formatPrice(total)}</span>
            </div>
          </Modal>
        </Spin>
      )}
    </div>
  );
};

export default CheckoutPage;
