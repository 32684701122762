import React from "react";
import modalLogo from "../img/kalapayFinalLogoCompressed.webp";
import "./WelcomeModal.css";
const WelcomeModal = ({ onClose }) => {
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center max-w-md w-full mx-4">
        <div className="mb-4">
          <img
            src={modalLogo}
            alt="Kalapay Logo"
            className="mx-auto w-32 h-auto"
          />{" "}
        </div>
        <h2 className="text-2xl font-bold mb-4">Welcome to Kalapay.shop!</h2>
        <p className="mb-4">
          We are thrilled to have you here. Enjoy your shopping!
        </p>
        <button
          className=" text-white px-4 py-2 rounded wbutton"
          onClick={onClose}
        >
          Start Shopping
        </button>
      </div>
    </div>
  );
};

export default WelcomeModal;
