import React, { useContext, useEffect, useState } from "react";
import { Form, Input, Button, message } from "antd";
import {
  UserOutlined,
  LockOutlined,
  EyeTwoTone,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { motion } from "framer-motion";
import styled from "styled-components";
import { UserContext } from "../contexts/UserContext";
import { CartContext } from "../contexts/CartContext";
import cover from "../img/coverCoverCompressed.webp";
import "./Login.css";
const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background: #f1e5d1;
  padding: 20px;
`;

const Card = styled(motion.div)`
  display: flex;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;

  @media (max-width: 768px) {
    flex-direction: column;
    width: 90%;
  }
`;

const FormContainer = styled.div`
  flex: 1;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (max-width: 768px) {
    padding: 30px;
  }
`;

const ImageContainer = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #803d3b;

  img {
    width: 420px;
    height: 100%;
    object-fit: cover;
  }

  @media (max-width: 768px) {
    order: -1;
  }
`;

const Title = styled(motion.h1)`
  font-size: 2.5rem;
  padding-bottom: 15px;
  color: #6f4e37;

  @media (max-width: 768px) {
    font-size: 2rem;
  }
`;

const StyledForm = styled(Form)`
  max-width: 400px;
  width: 100%;
  margin: 0 auto;

  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
`;

const LoginButton = styled(Button)`
  width: 100%;
  background-color: #74512d;
  color: white;
  &:hover {
    background-color: #af8260 !important;
  }
`;

const SignupLink = styled(Link)`
  display: block;
  text-align: center;
  margin-top: 10px;
`;

const Login = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { setCart } = useContext(CartContext);

  const fetchCartItems = async () => {
    try {
      if (user && user.id) {
        const cartResponse = await axios.get(
          "http://kalapay.shop/KalapayAPIupload/get_cart.php",
          {
            params: { user_id: user.id },
          }
        );
        const cartItems = cartResponse.data;
        setCart(cartItems);
        localStorage.setItem("cart", JSON.stringify(cartItems));
      }
    } catch (error) {
      console.error("An error occurred while fetching cart items:", error);
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (user && user.id) {
      fetchCartItems();
    }
  }, [user, setCart]);

  const onFinish = async (values) => {
    try {
      const response = await axios.post(
        "http://kalapay.shop/KalapayAPIupload/login.php",
        values
      );
      if (response.data.success) {
        message.success("Logged in successfully");
        const userData = {
          id: response.data.user.id,
          username: response.data.user.username,
          email: response.data.user.email,
        };
        setUser(userData);
        localStorage.setItem("user", JSON.stringify(userData));
        navigate("/");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("An error occurred:", error);
      message.error("An error occurred while logging in");
    }
  };

  return (
    <Container>
      <Card
        initial={{ opacity: 0, y: -50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
        className="card"
      >
        <FormContainer>
          <Title
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ delay: 0.5 }}
          >
            Log in
          </Title>

          <StyledForm
            form={form}
            name="normal_login"
            className="login-form"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Email"
              name="email"
              rules={[{ required: true, message: "Please input your Email!" }]}
            >
              <Input
                prefix={<UserOutlined />}
                placeholder="Email"
                style={{ marginTop: "6px" }}
              />
            </Form.Item>
            <Form.Item
              label="Password"
              name="password"
              rules={[
                { required: true, message: "Please input your Password!" },
              ]}
            >
              <Input.Password
                prefix={<LockOutlined />}
                placeholder="Password"
                iconRender={(visible) =>
                  visible ? <EyeTwoTone /> : <EyeInvisibleOutlined />
                }
              />
            </Form.Item>
            <Form.Item>
              <LoginButton
                type="primary"
                htmlType="submit"
                className="login-form-button"
              >
                Log in
              </LoginButton>
              <SignupLink to="/signup">No Account? Signup Here.</SignupLink>
            </Form.Item>
          </StyledForm>
        </FormContainer>
        <ImageContainer>
          <img src={cover} alt="Cover" />
        </ImageContainer>
      </Card>
    </Container>
  );
};

export default Login;
