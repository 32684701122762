import React, { useState, useEffect, useRef } from "react";
import { Badge, Dropdown, Card, List, Button } from "antd";
import { BellOutlined } from "@ant-design/icons";
import axios from "axios";
import moment from "moment";
import { formatPrice } from "../components/formatPrice";
import "./HeaderWithNotification.css";

const HeaderWithNotification = () => {
  const [newOrders, setNewOrders] = useState([]);
  const [closedOrders, setClosedOrders] = useState([]);
  const [isRinging, setIsRinging] = useState(false);
  const lastFetchTimeRef = useRef(new Date().toISOString());

  useEffect(() => {
    const savedOrders = JSON.parse(localStorage.getItem("newOrders")) || [];
    const savedClosedOrders =
      JSON.parse(localStorage.getItem("closedOrders")) || [];
    setNewOrders(savedOrders);
    setClosedOrders(savedClosedOrders);
  }, []);

  useEffect(() => {
    const fetchNewOrders = async () => {
      try {
        const response = await axios.get(
          "http://kalapay.shop/KalapayAPIupload/getOrdersNotif.php",
          {
            params: { lastFetchTime: lastFetchTimeRef.current },
          }
        );
        const newFetchedOrders = response.data.filter(
          (order) => !closedOrders.includes(order.id)
        );
        if (newFetchedOrders.length > 0) {
          const updatedOrders = [...newOrders, ...newFetchedOrders].filter(
            (order, index, self) =>
              self.findIndex((o) => o.id === order.id) === index
          );

          // Sort orders by created_at in descending order
          updatedOrders.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
          );

          setNewOrders(updatedOrders);
          localStorage.setItem("newOrders", JSON.stringify(updatedOrders));
          // Trigger the ringing effect
          setIsRinging(true);
          setTimeout(() => setIsRinging(false), 1000); // Duration of the ringing effect
        }
        lastFetchTimeRef.current = new Date().toISOString();
      } catch (error) {
        console.error("Error fetching new orders:", error);
      }
    };

    const interval = setInterval(fetchNewOrders, 2000);
    return () => clearInterval(interval);
  }, [closedOrders, newOrders]);

  const handleCloseNotification = (id) => {
    const updatedOrders = newOrders.filter((order) => order.id !== id);
    const updatedClosedOrders = [...closedOrders, id];
    setNewOrders(updatedOrders);
    setClosedOrders(updatedClosedOrders);
    localStorage.setItem("newOrders", JSON.stringify(updatedOrders));
    localStorage.setItem("closedOrders", JSON.stringify(updatedClosedOrders));
  };

  const notificationMenu = (
    <Card
      style={{
        width: 300,
        maxHeight: 400,
        overflowY: "auto",
        boxShadow: "0px 2px 15px 10px #efefef",
      }}
    >
      <h1 style={{ fontSize: "1.3rem" }}>New Orders:</h1>
      <List
        itemLayout="vertical"
        dataSource={newOrders}
        renderItem={(order) => (
          <List.Item key={order.id}>
            <List.Item.Meta
              title={<strong>Order ID: {order.id}</strong>}
              description={
                <>
                  <p>Customer: {order.username}</p>
                  <p>Total: {formatPrice(order.total)}</p>
                  <p>Added: {moment(order.created_at).fromNow()}</p>
                </>
              }
            />
            <ul style={{ paddingLeft: 20 }}>
              {order.order_items.map((item) => (
                <li
                  key={item.product_id}
                  style={{
                    borderBottom: "1px solid #ddd",
                    paddingBottom: "10px",
                    marginBottom: "10px",
                  }}
                >
                  <p style={{ fontFamily: "Raleway", fontWeight: "bold" }}>
                    {item.title}
                  </p>
                  <p>Price: {formatPrice(item.price)}</p>
                  <img
                    src={item.image}
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "6px",
                    }}
                  />
                  <p>Quantity: {item.quantity}</p>
                  {item.selectedSize && <p>Size: {item.selectedSize}</p>}
                </li>
              ))}
            </ul>
            <Button
              type="link"
              onClick={() => handleCloseNotification(order.id)}
            >
              Close
            </Button>
          </List.Item>
        )}
        locale={{ emptyText: "No new orders" }}
      />
    </Card>
  );

  return (
    <Dropdown overlay={notificationMenu} trigger={["click"]}>
      <Badge count={newOrders.length} offset={[0, 5]}>
        <BellOutlined
          className={isRinging ? "ringing" : ""}
          style={{ fontSize: "24px", cursor: "pointer" }}
        />
      </Badge>
    </Dropdown>
  );
};

export default HeaderWithNotification;
