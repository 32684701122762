import React, { createContext, useState, useEffect } from "react";
import axios from "axios";

export const ProductContext = createContext();

const ProductProvider = ({ children }) => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchProducts();
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  const fetchProducts = async () => {
    try {
      const response = await axios.get(
        "http://kalapay.shop/KalapayAPIupload/getProducts.php"
      );
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
  };

  const createProduct = async (formData) => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/createProduct.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchProducts(); // Refresh products list
    } catch (error) {
      console.error("Error creating product:", error);
    }
  };

  const updateProduct = async (formData) => {
    try {
      await axios.post(
        "http://kalapay.shop/KalapayAPIupload/updateProduct.php",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      fetchProducts(); // Refresh products list
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const deleteProduct = async (id) => {
    try {
      await axios.delete(
        "http://kalapay.shop/KalapayAPIupload/deleteProduct.php",
        {
          data: { id },
        }
      );
      fetchProducts(); // Refresh products list
    } catch (error) {
      console.error("Error deleting product:", error);
    }
  };

  const updateProductStock = (productId, newStock) => {
    setProducts((prevProducts) =>
      prevProducts.map((product) =>
        product.id === productId ? { ...product, stock: newStock } : product
      )
    );
  };

  return (
    <ProductContext.Provider
      value={{
        products,
        createProduct,
        updateProduct,
        deleteProduct,
        fetchProducts,
        updateProductStock,
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export default ProductProvider;
