import React, { useState, useEffect } from "react";
import axios from "axios";
import { Form, Input, Button, message } from "antd";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const AboutEdit = () => {
  const [form] = Form.useForm();
  const [aboutData, setAboutData] = useState({ title: "", content: "" });

  useEffect(() => {
    axios
      .get("http://kalapay.shop/KalapayAPIupload/about.php")
      .then((response) => {
        setAboutData(response.data);
        form.setFieldsValue({
          title: response.data.title,
          content: response.data.content,
        });
      })
      .catch((error) => {
        console.error("There was an error fetching the paragraph!", error);
      });
  }, [form]);

  const handleFormSubmit = (values) => {
    axios
      .post("http://kalapay.shop/KalapayAPIupload/updateAbout.php", values)
      .then((response) => {
        message.success("Paragraph updated successfully!");
      })
      .catch((error) => {
        console.error("There was an error updating the paragraph!", error);
      });
  };

  const handleQuillChange = (content) => {
    form.setFieldsValue({ content });
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={aboutData}
      onFinish={handleFormSubmit}
    >
      <Form.Item
        label="Title"
        name="title"
        rules={[{ required: true, message: "Please input the title!" }]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Content"
        name="content"
        rules={[{ required: true, message: "Please input the content!" }]}
      >
        <ReactQuill
          theme="snow"
          value={form.getFieldValue("content")}
          onChange={handleQuillChange}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit">
          Save
        </Button>
      </Form.Item>
    </Form>
  );
};

export default AboutEdit;
