import React, { createContext, useState, useEffect, useContext } from "react";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(() => {
    const savedAuthState = localStorage.getItem("isAuthenticated");
    const savedLoginTime = localStorage.getItem("loginTime");

    if (savedAuthState && savedLoginTime) {
      const isAuth = JSON.parse(savedAuthState);
      const loginTime = JSON.parse(savedLoginTime);
      const now = new Date().getTime();

      // Check if more than 1 hour has passed since last login
      if (isAuth && now - loginTime < 60 * 60 * 1000) {
        return true;
      }
    }

    return false;
  });

  const logout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem("isAuthenticated");
    localStorage.removeItem("loginTime");
  };

  useEffect(() => {
    if (isAuthenticated) {
      localStorage.setItem("isAuthenticated", JSON.stringify(true));
      localStorage.setItem("loginTime", JSON.stringify(new Date().getTime()));
    } else {
      logout();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    const interval = setInterval(() => {
      const loginTime = JSON.parse(localStorage.getItem("loginTime"));
      if (loginTime) {
        const now = new Date().getTime();
        if (now - loginTime >= 60 * 60 * 1000) {
          logout();
        }
      }
    }, 60 * 1000); // Check every minute

    return () => clearInterval(interval);
  }, []);

  return (
    <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
